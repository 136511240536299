import React, { Component } from 'react';
import Select from 'react-select';
import Api from '../services/api.js';
import helper from '../services/helper';

class Lookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            searchValue: props.value
        };
    }
    componentDidMount() {
        this.getCollection()
    }
    async getCollection() {
        let { schema } = this.props
        if (schema.dataset && schema.dataset.collectionName) {
            let rs = await Api.getCollection({
                collectionName: schema.dataset.collectionName
            })
            if (rs && rs.errorCode === 0) {
                rs.data.map(item => {
                    helper.renameKey(item, schema.fieldsSave, "value")
                    helper.renameKey(item, schema.modelSelectField, "label")
                    if (item.value === this.props.value) {
                        this.setState({ searchValue: item })
                    }
                })
                this.setState({ collection: rs.data })
            }
        }
        if (schema.dataList && schema.dataList.collectionName) {
            let rs = await Api.getDataListCollection({
                collectionName: schema.dataList.collectionName
            })
            if (rs && rs.errorCode === 0) {
                let collection = rs.data.map(d => ({
                    value: d[schema.fieldsSave],
                    label: this.calcLabelSelectOption(schema, d)
                }))
                let searchValue = collection.find(c => c.value === this.props.value)
                this.setState({ collection, searchValue })
            }
        }
    }
    calcLabelSelectOption(schema, data) {
        let result = []
        result = (schema.modelSelectField || []).map(m => (data[m.value]))
        return result.join(" - ")
    }
    onHandleChange = (e) => {
        this.setState({ searchValue: e })
        if (this.props.onChange) {
            this.props.onChange(e.value)
        }
    }

    render() {
        let { searchValue, collection } = this.state;
        let { schema } = this.props

        return <div className="d-flex">

            <Select
                isDisabled={schema.disabled}
                value={searchValue}
                onChange={e => this.onHandleChange(e)}
                options={collection}
                maxMenuHeight={220}
                className="width-100pc"
                styles={{
                    container: (p) => ({ position: "relative", boxSizing: "border-box" })
                }}
            />
        </div>
    }
}

export default Lookup;

