import React, { Component } from 'react';
import Select from 'react-select';
import Api from '../services/api.js';
import helper from '../services/helper';

class Lookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            searchValue: props.value
        };
        // this.getCollection()

    }
    async componentDidMount() {
        let { schema } = this.props
        if (schema.dataset && schema.dataset.collectionName) {
            let rs = await Api.getCollection({
                collectionName: schema.dataset.collectionName
            })
            if (rs && rs.errorCode === 0) {
                this.setState({ collection: rs.data })
            }
        }

    }
    componentWillReceiveProps(props) {
        this.setState({ searchValue: props.value })
        this.getCollection(props)


    }
    getCollection(props) {
        let { schema, value } = props
        let { collection } = this.state
        let searchValue = []
        collection.map(item => {
            helper.renameKey(item, schema.fieldsSave, "value")
            helper.renameKey(item, schema.modelSelectField, "label")
            if (value && value.length > 0) {
                let tem = value.find(ele => ele === item.value)
                if (tem) {
                    searchValue.push(item)
                }
            }
        })
        this.setState({ searchValue })
    }



    onHandleChange = (e) => {
        if (this.props.onChange) {
            this.setState({ searchValue: e })
            let value = []
            e !== null && e.map(el => value.push(el.value))
            this.props.onChange(value)
        }
    }

    render() {
        let { searchValue, collection } = this.state;
        let { schema } = this.props

        return <div className="d-flex">

            <Select
                isMulti={true}
                isDisabled={schema.disabled}
                value={searchValue}
                onChange={e => this.onHandleChange(e)}
                options={collection}
                maxMenuHeight={220}
                className="width-100pc "
            />

            <div className="lookupBtnSearch"
            // onClick={() => this.onClick()}
            >
                <i className="fa fa-search " />
            </div>
        </div>
    }
}

export default Lookup;

