import React from 'react'

import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
const ReactTableFixedColumns = withFixedColumns(ReactTable)

function TableViewer(props) {
  return (
    <div style={{ height: 'calc(100% - 2rem)', overflowY: 'auto' }}>
      <ReactTableFixedColumns
        className='-highlight '
        data={checkDataTable(props.schema?.value, props.schema.columns)}
        defaultPageSize={5}
        columns={props.schema.columns.map((column) => ({
          Header: column.name,
          accessor: column.fieldRefer,
        }))}
      />
    </div>
  )
}

export default TableViewer

const checkDataTable = (data, columns) => {
  if (!data) return []
  try {
    data = JSON.parse(data)
    if (!Array.isArray(data)) return []

    for (let column of columns) {
      if (!data[0].hasOwnProperty(column.fieldRefer)) {
        data.forEach((d) => {
          d[column.fieldRefer] = ''
        })
      }
    }
    return data
  } catch (error) {
    return []
  }
}
