/**
  * @editedBy: TamDV @EditedAt:04/12/2020
  * @description fix editor
*/
import React, { Component } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class CkEditorSchema extends Component {
    render() {

        return <SunEditor
            disable={this.props.disabled}
            setContents={this.props.value}
            onChange={(content) => {
                if (this.props.onChange)
                    this.props.onChange(content)
            }}
            setOptions={{
                minHeight: 250,
                // buttonList: buttonList.complex
                buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'preview']
                ]
            }}
        />
    }
}

export default CkEditorSchema

// const CkEditorSchema = (props) => {
// const handleEditorChange = (content) => {
//     if(props.onChange)
//     props.onChange(content)
// }
// return (
//     <SunEditor
//         disable={props.disabled}
//         setContents={props.value}
//         onChange={handleEditorChange}
//         setOptions={{
//             minHeight: 250,
//             // buttonList: buttonList.complex
//             buttonList: [
//                 ['undo', 'redo'],
//                 ['font', 'fontSize', 'formatBlock'],
//                 ['paragraphStyle', 'blockquote'],
//                 ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
//                 ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
//                 ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'preview']
//             ]
//         }}
//     />
// )
// }






