import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  Input,
} from "reactstrap";
import helper from "../../../services/helper";
import api from "../../../services/api";
import i18next from "i18next";
import ReactTable from "react-table";
import Preview from "../../Preview";
import Loader from "../../../controls/Loader";

function ApproveTask({ query, onChange, userInfo = null }) {
  const [data, setData] = useState({});
  const [srvAuthen, setSrvAuthen] = useState({});
  const [isLoader, setLoader] = useState(true);
  const [pagename, setPagename] = useState("");

  const [cols, setCols] = useState({});
  const [isPreview, setPreview] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [formConfirm, setFormConfirm] = useState();
  const [isApproved, setApproved] = useState(false);
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [authMethod, setAuthMethod] = useState("NONE");

  useEffect(() => {
    const getData = async () => {
      try {
        let result = await api.detailTask({ sid: query.sid }),
          evt = null,
          totalAmount = 0,
          cols = columns;
        if (result && result.errorCode === 0) {
          evt = result.data;
          if (evt.authorizeMatrix) {
            setSrvAuthen(evt.authorizeMatrix);
          }

          if (evt.eventType !== "manual") {
            if (!evt.dataExcel) evt.dataExcel = [];
            else {
              evt.totalRow = evt.dataExcel.length;
              evt.dataExcel.forEach((item) => {
                totalAmount += Number(item.amount);
              });
              evt.totalAmount = totalAmount;
            }
          }
          if (evt.status === "approved" && evt.eventType !== "manual") {
            let cols = [
              {
                Header: "Status",
                accessor: "status",
                filterMethod: (filter, row) =>
                  row[filter.id].includes(filter.value),
              },
              {
                Header: "Error Code",
                accessor: "errorCode",
                filterMethod: (filter, row) =>
                  row[filter.id].includes(filter.value),
              },
              {
                Header: "Error Msg",
                accessor: "errorMsg",
                filterMethod: (filter, row) => row[filter.id] == filter.value,
              },
            ];
            let accessors = columns[
              evt.metadata.serviceRef.toUpperCase()
            ][0].columns.map((i) => i.accessor);
            let index = accessors.findIndex(
              (i) => i === "status" || i === "errorCode" || i === "errorMsg"
            );
            if (index === -1)
              columns[evt.metadata.serviceRef.toUpperCase()][0].columns = [
                ...columns[evt.metadata.serviceRef.toUpperCase()][0].columns,
                ...cols,
              ];
            cols = columns;
          }
        }

        setPagename(query.page || "approveTask");
        setData(evt);
        setCols(cols);
        setLoader(false);
      } catch (error) {
        console.error("error.message", error.stack);
        helper.toast("error", i18next.t("internalServerError"));
      }
    };

    getData();
  }, []);

  const _showAlertConfirm = (isApprove) => {
    let form = "";
    setApproved(isApprove);
    if (
      !srvAuthen ||
      !srvAuthen.authentication ||
      !srvAuthen.authentication.checker ||
      !srvAuthen.authentication.checker.method ||
      srvAuthen.authentication.checker.method === "NONE"
    ) {
      if (!isApprove) {
        setConfirm(true);
      } else _onSubmit(isApprove);
    } else {
      setAuthMethod(srvAuthen.authentication.checker.method);
      if (
        srvAuthen.authentication.checker.method === "OTP" ||
        srvAuthen.authentication.checker.method === "2FA"
      ) {
        api.signOtpApproveOrRefusedTrans({
          serviceId: data.metadata.serviceId,
          isApproved: isApprove,
        });
        helper.toast("success", "Please check your email to take the otp.");
      }

      if (srvAuthen.authentication.checker.method === "PIN") {
        form = (
          <div className="swal2-html-container">
            <Input
              type="password"
              className="swal2-input"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        );
      }
      if (srvAuthen.authentication.checker.method === "OTP") {
        form = (
          <div className="swal2-html-container">
            <Input
              type="number"
              className="swal2-input"
              placeholder="OTP"
              style={{ maxWidth: "100%" }}
              onChange={(e) => setOTP(e.target.value)}
            />
          </div>
        );
      }
      if (srvAuthen.authentication.checker.method === "2FA") {
        form = (
          <div className="swal2-html-container">
            <Input
              type="password"
              className="swal2-input"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              type="number"
              className="swal2-input"
              placeholder="OTP"
              style={{ maxWidth: "100%" }}
              onChange={(e) => setOTP(e.target.value)}
            />
          </div>
        );
      }
      setFormConfirm(form);
      setConfirm(true);
    }
  };

  const _onSubmit = async (isApprove) => {
    try {
      let result = null,
        authentication = {};
      if (authMethod !== "NONE") {
        if (authMethod === "PIN") {
          if (!password) return helper.toast("error", i18next.t("plsEnterPin"));
          else authentication = { ...authentication, password: password };
        } else if (authMethod === "OTP") {
          if (!otp) return helper.toast("error", i18next.t("plsEnterOtp"));
          else authentication = { ...authentication, otp: otp };
        } else if (authMethod === "2FA") {
          if (!otp || !password)
            return helper.toast("error", i18next.t("plsEnterPinOrOTP"));
          else
            authentication = {
              ...authentication,
              otp: otp,
              password: password,
            };
        }
      }

      if (!isApprove) {
        if (!reason) return helper.toast("error", i18next.t("plsEnterReason"));
        else authentication = { ...authentication, reason: reason };
      }
      console.log("authentication", authentication);
      if (isApprove) {
        result = await api.approveTransaction({
          eventId: query.sid,
          authentication,
        });
      } else {
        result = await api.rejectTransaction({
          eventId: query.sid,
          authentication,
        });
      }
      if (result && result.errorCode === 0) {
        onChange({ message: i18next.t("successfully") });
        setConfirm(false);
      }
    } catch (error) {
      helper.toast("error", error.message || i18next.t("internalServerError"));
    }
  };
  const isShowBtn = () => {
    if (userInfo) {
      return data.checker.id === userInfo.sid;
    }
  };

  if (!query || !query.sid) return <Loader />;
  if (isLoader || !data) return <Loader />;
  return (
    <Col md={12}>
      <Row>
        {data.eventType === "manual" ? (
          <Col md={{ size: 7, offset: 3 }}>
            <Form>
              <FormGroup row>
                <Col
                  md={12}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "large",
                  }}
                >
                  {data.name}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("bankName")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.metadata.bankName}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("bankAccount")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.metadata.accountNoShowed}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("maker")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.maker.name}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("amount")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.metadata.amount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {i18next.t("MMK")}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("fee")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {(data.metadata.fee || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {i18next.t("MMK")}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("total")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.metadata.totalAmount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {i18next.t("MMK")}
                </Col>
              </FormGroup>
              {data.metadata.remark && (
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("remark")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {data.metadata.remark}
                  </Col>
                </FormGroup>
              )}
              <FormGroup row>
                <Col md={4} className="text-bold">
                  {i18next.t("note")}:{" "}
                </Col>
                <Col md={8} style={{ marginBottom: "20px" }}>
                  {data.metadata.note}
                </Col>
              </FormGroup>
              {data.status === "refused" && (
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("Reason")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {data.note}
                  </Col>
                </FormGroup>
              )}
              {data.status === "approved" && data.tracking && (
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("errorCode")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {data.tracking.errorCode}
                  </Col>
                </FormGroup>
              )}
              {data.status === "approved" && data.tracking && (
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("errorMsg")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {data.tracking.errorMsg}
                  </Col>
                </FormGroup>
              )}
              {data.invoiceData && (
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("invoiceNo")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    <Button onClick={() => setPreview(true)}>
                      {i18next.t("preview")}
                    </Button>
                    <br />
                  </Col>
                </FormGroup>
              )}
            </Form>
          </Col>
        ) : null}
        {data.eventType !== "manual" ? (
          <Col>
            <Row>
              <Col md={12}>
                <FormGroup row style={{ justifyContent: "center" }}>
                  <Label style={{ fontWeight: "bold", fontSize: "large" }}>
                    {data.name}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("maker")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {" "}
                    {data.maker.name}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("totalRow")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {" "}
                    {data.totalRow}
                  </Col>
                </FormGroup>
                {data.status === "refused" && (
                  <FormGroup row>
                    <Col md={4} className="text-bold">
                      {i18next.t("reason")}:{" "}
                    </Col>
                    <Col md={8} style={{ marginBottom: "20px" }}>
                      {data.note}
                    </Col>
                  </FormGroup>
                )}
                {data.invoiceData && (
                  <FormGroup row>
                    <Col md={4} className="text-bold">
                      {i18next.t("invoice")}:{" "}
                    </Col>
                    <Col md={8} style={{ marginBottom: "20px" }}>
                      <Button onClick={() => setPreview(true)}>
                        {i18next.t("preview")}
                      </Button>
                      <br />
                    </Col>
                  </FormGroup>
                )}
              </Col>
              <Col md={6}>
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("totalFee")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {(data.totalFee || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    {i18next.t("MMK")}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("totalMoney")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {" "}
                    {(data.totalAmount || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {i18next.t("MMK")}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4} className="text-bold">
                    {i18next.t("note")}:{" "}
                  </Col>
                  <Col md={8} style={{ marginBottom: "20px" }}>
                    {data.metadata.note}
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row className="table-responsive">
              <ReactTable
                data={data.dataExcel}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={cols[data.metadata.serviceRef.toUpperCase()]}
                defaultPageSize={10}
                className="-striped -highlight"
                showPageSizeOptions={false}
              />
            </Row>
          </Col>
        ) : null}
      </Row>
      {data.status === "pending" && pagename !== "myRequest" && isShowBtn() && (
        <>
          <hr />
          <Row style={{ justifyContent: "center" }}>
            <Button
              className="btn btn-success mr-2"
              onClick={() => _showAlertConfirm(true)}
            >
              <i className="fa fa-check" />
              &nbsp;
              {i18next.t("approve")}
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => _showAlertConfirm(false)}
            >
              <i className="fa fa-times" />
              &nbsp;
              {i18next.t("reject")}
            </Button>
          </Row>
        </>
      )}

      <Modal
        isOpen={isPreview}
        fade={false}
        size={"lg"}
        backdrop={true}
        keyboard={false}
      >
        <ModalHeader>
          <div className="pull-left">
            <h4>{i18next.t("invoice")}</h4>
          </div>
          <div className="pull-right">
            <a color="secondary" onClick={() => setPreview(false)}>
              <i className="fa fa-times" />
            </a>
          </div>
        </ModalHeader>
        {data.invoiceData && (
          <Preview
            objData={data.invoiceData}
            type={data.metadata.invoice.fileType}
          />
        )}
      </Modal>
      <Modal
        isOpen={isConfirm}
        fade={false}
        backdrop={true}
        keyboard={false}
        className="swal2-container"
      >
        <div className="swal2-header">
          <h2 className="swal2-title">{i18next.t("confirm")}</h2>
        </div>
        <div className="swal2-content">
          {formConfirm}
          {!isApproved ? (
            <Input
              placeholder="Reason"
              type="textarea"
              className="swal2-input"
              style={{ minHeight: "140px" }}
              onChange={(e) => setReason(e.target.value)}
            />
          ) : null}
        </div>
        <div className="swal2-actions">
          <Button
            type="button"
            className="swal2-confirm swal2-styled"
            onClick={() => _onSubmit(isApproved)}
            style={{ display: "inline-block" }}
          >
            {i18next.t("confirm")}
          </Button>
          <Button
            type="button"
            className="swal2-cancel swal2-styled"
            onClick={() => setConfirm(false)}
            style={{
              display: "inline-block",
              backgroundColor: "rgb(221, 51, 51)",
            }}
          >
            {i18next.t("cancel")}
          </Button>
        </div>
      </Modal>
    </Col>
  );
}

export default ApproveTask;
const columns = {
  CORPORATETRANSFERTONRIC: [
    {
      columns: [
        {
          Header: "No.",
          accessor: "no",
        },
        {
          Header: "Name",
          accessor: "name",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Beneficiary Phone",
          accessor: "beneficiaryPhone",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Beneficiary Nric",
          accessor: "beneficiaryNric",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Amount",
          accessor: "amount",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
        {
          Header: "Message",
          accessor: "message",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
      ],
    },
  ],
  CORPORATETRANSFERWALLET: [
    {
      columns: [
        {
          Header: "No.",
          accessor: "no",
        },
        {
          Header: "Name",
          accessor: "name",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Beneficiary Phone",
          accessor: "beneficiaryPhone",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Amount",
          accessor: "amount",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Message",
          accessor: "message",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
        {
          Header: "RefId",
          accessor: "refId",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
      ],
    },
  ],
  CORPORATETRANSFERTOMOBILE: [
    {
      columns: [
        {
          Header: "No.",
          accessor: "no",
        },
        {
          Header: "Name",
          accessor: "name",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Beneficiary Phone",
          accessor: "beneficiaryPhone",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Amount",
          accessor: "amount",
          filterMethod: (filter, row) => row[filter.id].includes(filter.value),
        },
        {
          Header: "Message",
          accessor: "message",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
        {
          Header: "RefId",
          accessor: "refId",
          filterMethod: (filter, row) => row[filter.id] == filter.value,
        },
      ],
    },
  ],
};
