import React, { Component } from "react";
import Widgets from "./Widgets";
import { Label } from "reactstrap";
import TableGenerate from "./TableGenerate";

class BaseWF extends Component {
  error() {
    if (this.isInvalid()) {
      return (
        <p className="text-danger">
          <b>
            {" "}
            {this.props.schema.name}
            &nbsp;is required!
          </b>
        </p>
      );
    }
    /**
     * @editedBy: TamDV @EditedAt: 01/06/2020
     * @description update check phone, email
     */
    if (this.props.value) {
      if (this.isInvalidRegex()) {
        return (
          <p className="text-danger">
            <b>
              {" "}
              {this.props.schema.name}
              &nbsp;is invalid!
            </b>
          </p>
        );
      }
    }
    if (this.isMaxLength()) {
      return (
        <p className="text-danger">
          <b>
            Length of {this.props.schema.name}
            &nbsp;is must smaller
            {this.props.schema.maxLength}
          </b>
        </p>
      );
    }
  }

  isInvalid() {
    if (
      this.props.schema.required &&
      (this.props.value === null || this.props.value === undefined || this.props.value === "")
    )
      return true;
  }
  /**
   * @editedBy: TamDV @EditedAt: 19/06/2020
   * @description update check phone, email
   */
  isInvalidRegex() {
    // const phoneRegex = ^(09)([0-9]{7,9})$;
    if (this.props.schema.regex) {
      const regex = new RegExp(this.props.schema.regex);
      return !regex.test(this.props.value);
    }
  }

  isMaxLength() {
    if (this.props.schema.maxLength > 0) {
      console.log(this.props.value.length + " " + this.props.schema.maxLength);
      return this.props.value.length > Number(this.props.schema.maxLength);
    }
  }

  render() {
    let colLeft = 3,
      colRight = 8,
      { schema, permission } = this.props;
    let styleLabel = "m-1 width-100pc pointer text-left ";
    if (permission && schema.hidden) {
      styleLabel += "text-line-through";
    }
    if (schema.widget === "CkEditorSchema") {
      colLeft = 2;
      colRight = 10;
    }
    if (schema.widget === "TableGenerate") {
      return (
        <TableGenerate schema={schema} value={this.props.value} onChange={this.props.onChange} />
      );
    } else {
      let Widget = Widgets[schema.widget];
      if (!Widget) {
        return <p>Invalid type {schema.widget}</p>;
      }

      return (
        <>
          <Label
            className={styleLabel}
            onClick={this.props.onClick}
            style={
              schema.widget === "Label"
                ? { backgroundColor: "#e9eaed", fontSize: "17px", padding: "3px 5px" }
                : {}
            }
          >
            {schema.name}
            {schema.required ? <span className="red ml-1">*</span> : ""}
          </Label>
          <Widget
            {...this.props}
            onChange={this.props.onChange}
            valueFilter={this.props.valueFilter}
            value={this.props.value === undefined ? schema.default : this.props.value}
            ARRAY={schema.items || []}
            schema={schema}
          />
        </>
      );
    }
  }
}

export default BaseWF;
