import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardGroup,
  Col, Container, Input, InputGroup,
  InputGroupAddon, InputGroupText, Row
} from 'reactstrap';
import api from '../../services/api.js';
import local from '../../services/local.js';
import Loader from '../../controls/Loader'
import i18next from 'i18next';
import queryString from "qs";
import Config from '../../services/config';

import DeviceDetector from "device-detector-js";
import helper from '../../services/helper';

const deviceDetector = new DeviceDetector();


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: true,
      captchaId: 0,
      captchaText: '',
      captcha: null,
      accountKitToken: '',
      disableLogin: false
    }
  }
  componentDidMount() {
    this.loadCaptcha();
    this.query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (this.query.data) {
      var data = JSON.parse(this.query.data)
      local.set('session', data.token);
      local.set('user', JSON.stringify(data.userInfo));
      this.props.history.push(`/dashboard`);
      console.log(this.query)
    }
  }

  async loadCaptcha() {
    try {
      let captInfo = await api.getCaptcha();
      this.setState({ loading: false, captchaId: captInfo.id, captcha: captInfo.data })
    } catch (e) {

    }
  }
  async onLoginClick(e) {
    e.preventDefault();
    try {
      this.setState({ disableLogin: true });
      let url = `${Config.host}/admin/user/loginWithPassword`;
      let option = {
        method: 'POST', // or 'PUT'
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
          captchaId: this.state.captchaId,
          captchaText: this.state.captchaText,
        }), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Basic ${Config.BASIC_TOKEN}`,
          'device': JSON.stringify(deviceDetector.parse(navigator.userAgent)),
          'subscription': localStorage.getItem('subscription') || null,
        }
      };


      let res = await fetch(url, option);
      let rs = await res.json();
      if (rs && rs.data) {
        local.set('session', rs.data.token);
        local.set('user', JSON.stringify(rs.data.userInfo));
        this.props.history.push(`/dashboard`);
        // } else {
        //   helper.toast('error', 'Account is not valid.')
        //   this.loadCaptcha()
        // }
      } else {
        this.loadCaptcha()
        this.setState({ captchaText: "", disableLogin: false });
        helper.toast("error", rs.errorMsg)
      }
    } catch (err) {
      console.log("loadCaptcha", err)
      this.loadCaptcha();
      this.setState({ captchaText: "", disableLogin: false });
      // 
      // helper.toast('error', err.message);
    }
    // return false;
  }
  async handleAccountKitResponse(result) {
    console.log('on token', result);
    this.setState({ accountKitToken: result.code });
  }
  forgotPassword() {
    this.props.history.push('/forgotPassword');
  }

  render() {
    let { disableLogin } = this.state
    if (this.state.loading) return <Loader />
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>

                      <Col md={12} style={{ textAlign: 'center' }}>
                        <img src='assets/img/logo.png' className='login-logo' alt='avatar' /><br />
                        <span style={{ fontSize: '20px', fontFamily: 'initial' }}>Corporate </span>
                      </Col>
                    </Row><br /><br />


                    <form onSubmit={this.onLoginClick.bind(this)} autoComplete='off'>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder={i18next.t('Username')} value={this.state.username} onChange={evt => this.setState({ username: evt.target.value })} />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder={i18next.t('password')} value={this.state.password} autoComplete='off' onChange={evt => this.setState({ password: evt.target.value })} />
                      </InputGroup>

                      <Row className='mb-4'>
                        <Col md={6}>
                          <div className='captcha' dangerouslySetInnerHTML={{ __html: this.state.captcha }}></div>
                        </Col>
                        <Col md={6}>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <Input type="text" placeholder={i18next.t('Please Enter Captcha')} value={this.state.captchaText} onChange={evt => this.setState({ captchaText: evt.target.value })} />
                              <InputGroupAddon addonType="prepend">
                                <Button color='light' type='button' onClick={() => {
                                  this.loadCaptcha();
                                }}><i className='fa fa-refresh' /></Button>
                              </InputGroupAddon>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Button color="primary" type='submit'
                            className="color-logo-corporate" block
                            disabled={disableLogin}>
                            {i18next.t('login')}
                          </Button>
                        </Col>
                        <Col xs="12" className="text-align-center my-2">
                          <span >
                            {i18next.t('OR')}
                          </span>
                        </Col>
                        <Col xs="12">
                          <a href={Config.host + '/auth/google'}
                            className="btn btnLogin3rdParty my-2"
                          >
                            <i className="fa fa-google pull-left" />
                            <span className="spanLogin">
                              {i18next.t('loginGoogle')}
                            </span>

                          </a>
                        </Col>
                        <Col xs="12">

                          <a href={Config.host + '/auth/microsoft'}
                            className="btn btnLogin3rdParty my-2"
                          >
                            <i className="fa fa-windows pull-left" />
                            <span className="spanLogin">
                              {i18next.t('loginMicrosoft')}
                            </span>
                          </a>
                        </Col>

                        <Col xs="12" className="text-right">
                          <Button color="link" onClick={() => this.forgotPassword()} className="px-0" type='button' block>{i18next.t('forgotPassword')}</Button>
                        </Col>
                      </Row>
                    </form>

                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
