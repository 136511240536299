import React, { Component } from 'react';
import JSONInput from 'react-json-editor-ajrm/index';
class TextJson extends Component {
    render() {
        return (<JSONInput
            placeholder={this.props.value}
            theme={'light_mitsuketa_tribute'}
            colors={{
                default:'black'
              }}
            width={'100%'}
            height={this.props.height || '250px'}
            onChange={e => {
                if (e.error) return;
                if (this.props.onChange) {
                    this.props.onChange(e.jsObject)
                }
            }}
        // viewOnly={this.props.disabled}
        />)
    }
}

export default TextJson;