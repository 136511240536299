import React, { useState, useEffect } from 'react';
import helper from '../services/helper';
import request from '../services/request';
import api from '../services/api';
import Select from 'react-select';

function ReactSelectFetch({ schema = {}, value, onChange, isMulti = false }) {
    const LIMIT = 10
    const [options, setOptions] = useState([]);
    const [skip, setSkip] = useState(0)
    const [valueSt, setValue] = useState({})

    const onScroll = async (event) => {
        const scrollY = event.target.scrollTop + event.target.clientHeight
        const scrollHeight = event.target.scrollHeight
        if ((options.length % 10 == 0) && scrollHeight * 0.95 <= scrollY <= scrollHeight) {
            fetchData(skip + 10)
        }
    }
    const fetchData = async (skip) => {
        try {

            let rs, pageInfo;
            if (!schema.pageId) {
                if (schema.api) {
                    rs = await api.getFetchApiBySid({ sid: schema.api })
                    if (rs && rs.errorCode === 0) {
                        let api = rs.data[0];
                        if (api) {
                            let param = JSON.parse(api.params);
                            param.skip = skip
                            rs = await request.request(api.url, param, {}, api.method)
                        } else {
                            console.log(rs)
                        }

                    }
                }

            } else {
                pageInfo = await helper.getPage(schema.pageId);
                rs = await helper.callPageApi(pageInfo, schema.api, { skip, limit: LIMIT }) || {};
            }

            let tempArr = options

            if (rs && rs.errorCode === 0) {
                if (rs.data && rs.data.length > 0) {
                    tempArr = tempArr.concat(rs.data)
                    if (skip === 0 && value) {
                        let check = tempArr.filter(tem => tem[schema.fieldsSave] === value)
                        if (check.length === 0) {
                            let rstem = await helper.callPageApi(pageInfo, schema.apiDetail, { [schema.fieldsSave]: value });
                            if (rstem && rstem.errorCode === 0) {
                                tempArr.push(rstem.metadata)
                            }
                        }
                    }
                    tempArr.map(t => {
                        if (t[schema.fieldsSave]) {
                            helper.renameKey(t, schema.fieldsSave, "value")
                        }
                        if (Array.isArray(schema.modelSelectField)
                            && schema.modelSelectField.length > 0) {
                            tempArr.map(e => {
                                let label = ""
                                schema.modelSelectField.map((m, i) => {
                                    label += e[m.value]
                                    if (i < (schema.modelSelectField.length - 1))
                                        label += " - "
                                })
                                e.label = label
                            })

                        } else
                            if (t[schema.modelSelectField]) {
                                helper.renameKey(t, schema.modelSelectField, "label")
                            }
                        if (t.value === value) setValue(t)
                    })
                    tempArr = getUnique(tempArr, "value")
                    setOptions(tempArr)
                    setSkip(skip)
                }
            }
        } catch (error) {
            console.log(error)
            helper.toast("error", "System Error")
        }
    }
    function getUnique(arr, index) {
        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }
    useEffect(() => {
        fetchData(skip);
        if (isMulti) {
            setValue([])
        }

    }, [])
    const onHandleChange = (e) => {
        if (isMulti) {
            let arr = [];
            if (e !== null) e.map(ele => ele.value ? arr.push(ele.value) : "")
            onChange(arr)
            setValue(e)

        } else {
            onChange(e.value)
            setValue(e)
        }

    }
    return (
        <div onScroll={(e) => onScroll(e)}>
            <Select
                isDisabled={schema.disabled}
                value={valueSt}
                onChange={e => onHandleChange(e)}
                options={options}
                maxMenuHeight={220}
                isMulti={isMulti}
            />
        </div>
    )
}

export default ReactSelectFetch;
