// import React, { Component } from 'react';
// import { AppSwitch } from '@coreui/react'
// class Checkbox extends Component {
//     constructor(props) {
//         super(props);
//         if (props.value === null || props.value === undefined) {
//             if (props.onChange) {
//                 props.onChange(true);
//             }
//         }
//         this.state = {
//             value: !!props.value ? true : false
//         };
//     }
//     componentWillReceiveProps(next) {
//         this.setState({ checked: next.value });
//     }
//     render() {
//         return (<div>
//             <AppSwitch
//                 color={'success'}
//                 disabled={this.props.disabled}
//                 onChange={e => {
//                     if (this.props.onChange) {
//                         this.props.onChange(e.target.checked);
//                     }
//                 }}
//                 checked={this.props.value} />
//         </div>)
//     }
// }

// export default Checkbox;
import React, { Component } from 'react';
// import { AppSwitch } from '@coreui/react'
import SwitchButton from 'bootstrap-switch-button-react';
class Checkbox extends Component {
    constructor(props) {
        super(props);
        //         props.onChange(true);
        //     }
        // }
        this.state = {
            value: !!props.value ? false : true
        };
    }
    render() {
        console.log(this.props)
        return (<div>
            {this.props?.schema?.disabled &&
                <div className="not-allowed cb-disabled w-105p" />
            }

            <SwitchButton
                checked={this.props.value}
                onlabel='Yes'
                onstyle='success'
                offlabel='No'
                offstyle='danger'
                onChange={e => {
                    if (this.props?.onChange && !this.props?.schema?.disabled) {
                        this.props.onChange(e);
                    }
                }}
                style="w-105p"
            />

        </div>)
    }
}
export default Checkbox;