import React from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import helper from '../services/helper';

export default class SearchSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
            schema: props.schema || {},
            dataSelect: [],
            search: '',
            fieldsSave: []
        }
    }
    pageInfo = null;
    async componentDidMount() {
        let { schema } = this.props;
        this.pageInfo = await helper.getPage(schema.pageId);
        this.fetchData()
    }
    async componentWillReceiveProps(next) {
        await this.setState({
            value: next.value,
            schema: next.schema || {},
            pageInfo: next.pageInfo || {},
            search: next.search || ''
        })

        if (this.props.value !== next.value) this.fetchData()
        else {
            let value = this.state.dataSelect.find(i => i.code === next.value)
            await this.setState({ value })
        }
    }
    async fetchData(evt) {
        try {
            let { search, schema, value } = this.state;
            let fieldsSave = schema.fieldsSave && schema.fieldsSave.split(',')
            this.setState({ fieldsSave })

            let modelSelectField = schema.modelSelectField.split(','), query = {}
            if (modelSelectField.length > 1) {
                query.or = [
                    { [schema.modelSelectField.split(',')[0]]: { 'contains': search } },
                    { [schema.modelSelectField.split(',')[1]]: { 'contains': search } },
                ]
            } else query = { [schema.modelSelectField.split(',')[0]]: { 'contains': search } }


            if (evt && evt.key === "Enter") {
                let rs = await helper.callPageApi(this.pageInfo, schema.api, {
                    select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`,
                    queryInput: JSON.stringify({ ...query })
                });
                if (rs && rs.data) {
                    if (rs.data.length > 0) {
                        if (fieldsSave.length > 1) {
                            rs.data.map(item => {
                                this.renameKey(item, fieldsSave[0], 'value')
                                this.renameKey(item, fieldsSave[1], 'label')
                            })
                        } else {
                            let modelSelectField = schema.modelSelectField.split(',');
                            rs.data.map(item => {
                                this.renameKey(item, schema.fieldsSave, 'value')
                                if (modelSelectField.length > 1) {
                                    if (modelSelectField.includes(schema.fieldsSave)) item.label = item[modelSelectField[0]] + ' - ' + item.value
                                    else item.label = item[modelSelectField[0]] + ' - ' + item[modelSelectField[1]]
                                }
                                else this.renameKey(item, modelSelectField[0], 'label')

                            })
                        }
                    }
                }
                this.setState({ dataSelect: rs && rs.data || [], value: null })
            } else {
                if (value) {
                    let number;
                    if (fieldsSave.indexOf('title') === 1) number = fieldsSave.findIndex(i => i === 'title')
                    let rs = await helper.callPageApi(this.pageInfo, schema.api, {
                        select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`,
                        queryInput: JSON.stringify({ [fieldsSave[0]]: typeof value === 'string' ? value : value[fieldsSave[0]] })
                    });
                    if (rs && rs.data) {
                        if (rs.data.length > 0) {
                            if (fieldsSave.length > 1) {
                                rs.data.map(item => {
                                    this.renameKey(item, fieldsSave[0], 'value')
                                    this.renameKey(item, fieldsSave[1], 'label')
                                })
                            } else {
                                let modelSelectField = schema.modelSelectField.split(',');
                                rs.data.map(item => {
                                    this.renameKey(item, schema.fieldsSave, 'value')
                                    if (modelSelectField.length > 1) {
                                        if (modelSelectField.includes(schema.fieldsSave)) item.label = item[modelSelectField[0]] + ' - ' + item.value
                                        else item.label = item[modelSelectField[0]] + ' - ' + item[modelSelectField[1]]
                                    }
                                    else this.renameKey(item, modelSelectField[0], 'label')

                                })
                            }
                            value = rs.data.find(item => typeof value === 'object' ? value[fieldsSave[0]] === item.value : value === item.value)
                        }
                        this.setState({ dataSelect: rs && rs.data || [], value: value || {} })
                    }
                } else {
                    let number;
                    if (fieldsSave.indexOf('title') === 1) number = fieldsSave.findIndex(i => i === 'title')

                    // let rs = await helper.callPageApi(this.pageInfo, schema.api, {
                    //     select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`,
                    //     queryInput: JSON.stringify({ [fieldsSave[0]]: typeof value === 'string' ? value : value[fieldsSave[0]] })
                    // });
                    let rs = await helper.callPageApi(this.pageInfo, schema.api, {
                        select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`
                    });
                    if (rs && rs.data) {
                        if (fieldsSave.length > 1) {
                            rs.data.map(item => {
                                this.renameKey(item, fieldsSave[0], 'value')
                                this.renameKey(item, fieldsSave[1], 'label')
                            })
                        } else {
                            let modelSelectField = schema.modelSelectField.split(',');
                            rs.data.map(item => {
                                this.renameKey(item, schema.fieldsSave, 'value')
                                if (modelSelectField.length > 1) {
                                    if (modelSelectField.includes(schema.fieldsSave)) item.label = item[modelSelectField[0]] + ' - ' + item.value
                                    else item.label = item[modelSelectField[0]] + ' - ' + item[modelSelectField[1]]
                                }
                                else this.renameKey(item, modelSelectField[0], 'label')

                            })
                        }
                        this.setState({ dataSelect: rs && rs.data || [], value: null })
                    }
                }
            }
        } catch (e) {
            console.warn('error fetch data search select  ' + e.stack)
            helper.toast('error', 'Internal Server Error')
        }
    }
    renameKey(obj, old_key, new_key) {
        if (obj[old_key])
            if (old_key !== new_key) {
                Object.defineProperty(obj, new_key, // modify old key 
                    // fetch description from object 
                    Object.getOwnPropertyDescriptor(obj, old_key));
                delete obj[old_key];                // delete old key 
            }
    }

    render() {
        let { value, schema, dataSelect, search, fieldsSave } = this.state;

        if (!schema.modelSelectField && !schema.fieldsSave) return <p>Missing field</p>

        // if (dataSelect.length === 0) {
        //     return (
        //         <>
        //             <Input type="text" value={search} onChange={e => this.setState({ search: e.target.value })} onKeyPress={e => { this.fetchData(e) }} placeholder="Search and Choose" />
        //         </>
        //     )
        // } else {
        return (
            <>
                <Input type="text" value={search} onChange={e => this.setState({ search: e.target.value })} onKeyPress={e => { this.fetchData(e) }} style={{ marginBottom: '5px' }} />
                <Select options={dataSelect} value={value} isClearable={true} onChange={e => {
                    if (!e) {
                        this.setState({ dataSelect: [] })
                    } else {
                        if (fieldsSave.length > 1) {
                            let number;
                            if (fieldsSave.indexOf('title') === 1) number = fieldsSave.findIndex(i => i === 'title')
                            this.setState({
                                value: {
                                    [fieldsSave[0]]: e.value,
                                    [number ? [fieldsSave[number]] : [fieldsSave[1]]]: e.label
                                }
                            })
                            if (this.props.onChange) {
                                this.props.onChange({
                                    [fieldsSave[0]]: e.value,
                                    [number ? [fieldsSave[number]] : [fieldsSave[1]]]: e.label
                                })
                            }
                        } else {
                            this.setState({ value: e.value })
                            if (this.props.onChange) {
                                this.props.onChange(e.value)
                            }
                        }
                    }
                }} />
            </>
        )
        // }
    }
}