import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Table, Button } from "reactstrap";
import ReactTable from "react-table";
import BaseWF from "../../../../schema/BaseWF";
import _ from "lodash";
import PermissionField from "./PermissionField";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DATASET from '../../../../services/dataset.json'

import "../Form/form.css";
import "antd/dist/antd.css";
import { Steps, Popover } from "antd";
const { Step } = Steps;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class GridLayoutView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
      showPermissionFiled: false,
      currentSchema: {},
      currentKey: -1,
      current: 0,
    };
  }
  async componentDidMount() {
    if (
      this.state.section.sectionType === "table" &&
      this.state.section.hasOwnProperty("dataTable")
    ) {
      let section = _.cloneDeep(this.state.section);
      let sumCol = {};
      section.dataTable.forEach((r) => {
        section.columns.forEach((s) => {
          if (s.toSum) {
            if (sumCol[s.name] !== undefined) sumCol[s.name] += r[s.name];
            else sumCol[s.name] = r[s.name];
          }
        });
      });
      let col = [
        {
          Header: "No",
          width: 50,
          fixed: "left",
          columns: [
            {
              Header: "Sum",
              accessor: "no",
            },
          ],
        },
      ];
      section.columns.map((s) => {
        col.push({
          Header: s.name,
          columns: [
            {
              Header: sumCol[s.name]
                ? Number.isFinite(sumCol[s.name])
                  ? sumCol[s.name]
                  : "error data"
                : "",
              accessor: s.name,
            },
          ],
        });
      });
      console.log(col);
      await this.setState({ section, col });
    } else if (this.state.section.sectionType === "steps") {
      let { current } = this.state,
        { steps } = this.state.section;
      this.setState({ stepId: steps[current].sid });
    }
  }
  onChange(value, schema, idx) {
    let newSections = this.state.section;
    newSections.schema[idx].value = value;
    if (this.props.onChange) this.props.onChange(newSections);
  }
  handleDoubleClick(currentSchema, currentKey) {
    // debugger
    if (this.props.allowDoubleClick) {
      this.setState({
        showPermissionFiled: !this.state.showPermissionFiled,
        currentSchema,
        currentKey,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.section !== this.state.section){
      this.setState({section: nextProps.section});
    }
  }
  handlePermissionField(field, access, showPermissionFiled) {
    if (this.props.handlePermissionField) {
      this.setState({ showPermissionFiled });
      this.props.handlePermissionField(field, access);
    }
  }
  // findSchema(schema, stepId) {
  //     return schema.filter(e => e.stepId === stepId)
  // }
  //  customDot = (dot, { status, index }) => (
  //   <Popover
  //     content={
  //       <span>ds
  //         {/* step {index} status: {status} */}
  //       </span>
  //     }
  //   >
  //     {dot}
  //   </Popover>
  // );
  render() {
    let { section, showPermissionFiled, currentSchema, currentKey, current } = this.state;
    let col = [],
      { steps, schema } = section || [];
    if (steps && steps[current].sid) {
      schema = section.schema.filter((e) => e.stepId === steps[current].sid);
    }
    return (
      <div>
        {section.sectionType === "steps" && (
          <div style={{ minHeight: "8em" }}>
            <Steps
              current={current}
              // progressDot
              labelPlacement="vertical"
            //  progressDot={this.customDot()}
            >
              {steps &&
                steps.length > 0 &&
                steps.map((item, idx) => <Step key={item.title + idx} title={item.title} />)}
            </Steps>
            <div className="steps-content">
              {(steps && steps[current] && (
                <ResponsiveReactGridLayout
                  rowHeight={37}
                  cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
                  layout={schema}
                  isDraggable={false}
                  isResizable={false}
                >
                  {schema.map((sch, i) => {
                    if(sch.dataset && sch.saveField && sch.displayfield){
                      if(sch.referenceField){
                        const value = section.schema.filter((item)=>(item.field === sch.referenceField))[0]?.value
                        const items = DATASET[sch.dataset].filter((data)=> data.ref === value).reduce((acc,cur)=>[...acc,{value:cur[sch.saveField],key:cur[sch.displayfield]}],[])
                          // if(items.length>0 && !sch.value){
                          //   sch["value"] = items[0].value
                          // }
                        sch['items'] = items
                        
                      }
                      else{
                        const items = DATASET[sch.dataset].reduce((acc,cur)=>[...acc,{value:cur[sch.saveField],key:cur[sch.displayfield]}],[])
                        // if(items.length>0 && !sch.value){
                        //   sch["value"] = items[0].value
                        // }
                        sch['items'] = items
                      }
                    }
                    let key = i + section.sectionType;
                    if (sch.key === ("lookup" || "lookupList")) {
                      key += sch.value;
                    }
                    let style = "m-1-2 ";
                    if (sch.key === "reactSelectFetch") {
                      style += "z-index-10";
                    } else if (sch.key === "lookupList" && sch.value && sch.value.length > 2) {
                      sch.h = 2;
                      style += "z-index-10";
                    } else if (sch.key === "processInfo") {
                      style += " border-processInfo";
                    }
                    return (
                      <div
                        className={style}
                        data-grid={sch}
                        key={sch.i}
                        onDoubleClick={() => {
                          let idx = section.schema.findIndex((e) => e.field === sch.field);
                          this.handleDoubleClick(section.schema[idx], idx);
                        }}
                      >
                        <BaseWF
                          {...this.props}
                          schema={sch}
                          value={sch.value}
                          onChange={(value) => {
                            let idx = section.schema.findIndex((e) => e.field === sch.field);
                            this.onChange(value, sch, idx);
                          }}
                          permission={true}
                        />
                        {showPermissionFiled && (
                          <PermissionField
                            showPermissionFiled={showPermissionFiled}
                            currentSchema={currentSchema || {}}
                            schema={sch || {}}
                            keyF={section.schema.findIndex((e) => e.field === sch.field)}
                            currentKey={currentKey}
                            handlePermissionField={(field, access, showPermissionFiled) =>
                              this.handlePermissionField(field, access, showPermissionFiled)
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </ResponsiveReactGridLayout>
              )) ||
                "Add at least a step to can drop schema"}
            </div>
            <div className="steps-action">
              {current > 0 && (
                <Button className="m-1" onClick={() => this.setState({ current: current - 1 })}>
                  Previous
                </Button>
              )}
              {steps && current < steps.length - 1 && (
                <Button className="m-1" onClick={() => this.setState({ current: current + 1 })}>
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
        {section.sectionType === "section" && (
          <>
            <ResponsiveReactGridLayout
              rowHeight={35}
              cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
              layout={section.schema}
              isDraggable={false}
              isResizable={false}
            >
              {section.schema.map((schema, i) => {
                // let key = i + section.sectionType;
                // if (schema.key === ("lookup" || "lookupList")) {
                //     key += schema.value
                // }
                let style = "m-1-2 ";
                if (schema.key === "reactSelectFetch") {
                  style += "z-index-10";
                } else if (schema.key === "lookupList" && schema.value && schema.value.length > 2) {
                  schema.h = 2;
                  style += "z-index-10";
                }
                else if (schema.key === "processInfo") {
                  style += " border-processInfo";
                }
                return (
                  <div
                    className={style}
                    data-grid={schema}
                    key={schema.i}
                    onDoubleClick={() => this.handleDoubleClick(schema, i)}
                  >
                    <BaseWF
                      {...this.props}
                      schema={schema}
                      value={schema.value}
                      onChange={(value) => {
                        this.onChange(value, schema, i);
                      }}
                      permission={true}
                    />
                    {showPermissionFiled && (
                      <PermissionField
                        showPermissionFiled={showPermissionFiled}
                        currentSchema={currentSchema || {}}
                        schema={schema || {}}
                        keyF={i}
                        currentKey={currentKey}
                        handlePermissionField={(field, access, showPermissionFiled) =>
                          this.handlePermissionField(field, access, showPermissionFiled)
                        }
                      />
                    )}
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
          </>
        )}
      </div>
    );
  }
}

export default GridLayoutView;

