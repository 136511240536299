import React, { useEffect, useState } from 'react'
import {
    Button, Col, Input, Row, Label
} from 'reactstrap';
import i18next from 'i18next';
import api from '../../../services/api';
import helper from '../../../services/helper';


const SignUpForm = ({ pCompany, history }) => {

    const [company, setCompany] = useState({})
    const onChangeCompany = (prop, value) => {
        const tem = Object.assign({}, company);
        tem[prop] = value;
        setCompany(tem)
    }

    useEffect(() => {
        setCompany(pCompany)
    }, [])
    const onLoginClick = async () => {
        try {
            let { phone, organizationName, email, name, organizationDomain, address, avatar } = company;

            let rs = await api.signUpWithGoogle({
                phone, organizationName, email, name, organizationDomain, address, avatar
            });
            if (rs && rs.errorCode === 0) {
                helper.toast("success", "Please check your mail to get the password")
                history.push(`/login`);
            }
        } catch (err) {
            console.log("loadCaptcha", err)
        }
    }
    return (
        <div>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('Name')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        placeholder={i18next.t('Name')}
                        value={company.name || ""}
                        onChange={e => onChangeCompany("name", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('phone')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        value={company.phone}
                        onChange={e => onChangeCompany("phone", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('Email')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        value={company.email}
                    // onChange={e => onChangeCompany("email", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('address')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        value={company.address}
                        onChange={e => onChangeCompany("address", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('organizationName')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        value={company.organizationName}
                        onChange={e => onChangeCompany("organizationName", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md="5" className="d-flex align-items-center">
                    <Label className="mb-0">
                        {i18next.t('organizationDomain')}
                        <span className="red ml-1">*</span>
                    </Label>
                </Col>
                <Col md="7">
                    <Input type="text"
                        placeholder="example.funflow.com"
                        value={company.organizationDomain}
                        onChange={e => onChangeCompany("organizationDomain", e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md="12">
                    {i18next.t('acceptedPolice')}
                    <a href="#" className="link-color"> Term of Service </a>&
                    <a href="#" className="link-color"> Privacy Policy</a>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Button color="primary" type='submit'
                        className="color-logo-corporate" block
                        onClick={() => onLoginClick()}
                    >
                        {i18next.t('signUp')}
                    </Button>
                </Col>


            </Row>
        </div>
    )
}

export default SignUpForm
