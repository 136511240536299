import React from "react"
import { Button } from "reactstrap"
import api from "../services/api"
import helper from "../services/helper"
import local from "../services/local"
import _ from "lodash"

const ButtonSchema = ({ schema, handleChangeSections, sections }) => {
  let { color } = schema.button

  const mappingParams = async (inputs) => {
    try {
      const listSchema = sections.map((section) => section.schema).flat()
      const companyConfigs = (await api.getSettingCompany({ sid: local.get("user").company })).companyCf
      console.log(companyConfigs)
      let params = {}
      inputs.forEach((input) => {
        if (input.source === "form") params[input.key] = listSchema.find((schema) => schema.field === input.ref)?.value || ""
        if (input.source === "system") params[input.key] = companyConfigs[input.ref]
      })
      return params
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  const handleClick = async () => {
    if (schema.button.type === "Download") {
      window.open(schema.button.link, "_blank")
    }
    if (schema.button?.type === "httpRequest") {
      let params = await mappingParams(schema.button.inputs)

      let response
      try {
        response = await api.doHttpRequest({
          data: { method: schema.button.method, url: schema.button.link, params },
        })
        response = response.data
      } catch (error) {
        console.error(error)
        return helper.toast("error", "Error Http Request")
      }

      let _sections = _.cloneDeep(sections)
      let _listSchema = _sections.map((section) => section.schema).flat()
      schema.button.outputs.forEach((output) => {
        let schemaToChangeValue = _listSchema.find((sch) => sch.field === output.ref)
        if (schemaToChangeValue) {
          let responseValue = calcResponseValue(response, output.key)
          if (schemaToChangeValue.key === "tableViewer") {
            schemaToChangeValue.value = JSON.stringify(responseValue)
          } else {
            schemaToChangeValue.value = responseValue
          }
        }
      })
      handleChangeSections(_sections)
    }
  }
  return (
    <Button className='minW-6' onClick={handleClick} color={color} disabled={schema.disabled || false}>
      {schema.name}
    </Button>
  )
}

export default ButtonSchema

const calcResponseValue = (response, key) => {
  let arrKey = key.split(".")
  return arrKey.reduce((acc, key) => acc?.[key], response)
}
