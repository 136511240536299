import React from 'react'
import SignaturePad from 'react-signature-canvas'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
export default class Signature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            trimmedDataURL: props.value
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ trimmedDataURL: props.value })
    }
    state = { trimmedDataURL: null }
    sigPad = {}
    clear = () => {
        this.sigPad.clear()
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png'), isShow: false
        })
        if (this.props.onChange)
            this.props.onChange(this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png'));
    }
    render() {
        let { trimmedDataURL, isShow } = this.state
        let { schema } = this.props
        return <>
            {isShow &&
                <Modal
                    isOpen={this.state.isShow}
                    fade={false}
                    size={"lg"}
                    backdrop={true}
                    keyboard={false}
                >
                    <ModalHeader>
                        <div className="pull-left">
                            Create Signature
                        </div>
                        <div className="pull-right">
                            <a color="secondary"
                                onClick={() => {
                                    this.setState({ isShow: false });
                                }}
                            >
                                <i className="fa fa-times" />
                            </a>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <SignaturePad canvasProps={{ width: 800, height: 450 }}
                            ref={(ref) => { this.sigPad = ref }} />
                    </ModalBody>
                    <ModalFooter>
                        <div className="pull-right">
                            <Button color="success" className="mr-2" onClick={this.trim}>
                                Save
                            </Button>
                            <Button color="danger" onClick={this.clear}>
                                Clear
                        </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            }

            <Button className="ml-1"
                disabled={schema.disabled}
                onClick={() => this.setState({ isShow: true })}>
                Create Signature
                </Button>

            {trimmedDataURL
                ? <img className="signature-img"
                    src={trimmedDataURL} />
                : null}
        </>
    }
}

