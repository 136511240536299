import React, { useState, useEffect, createRef, useRef } from "react";
import { Link } from "react-router-dom";
import myColor from "../../../services/color";
import { DropDownNavBar } from "./DropDownNavBar";
import "./simplenavbar.scss";

const SimpleNavBar = (props) => {
  const [height, setHeight] = useState([]);
  const [actTab, setActTab] = useState(0);
  const elementsRef = useRef(props.items?.map(() => createRef())); // Dynamic Ref
  useEffect(() => {
    setNewHeight();
  }, []);

  const setNewHeight = () => {
    const nextHeight = elementsRef.current.map(
      (ref) => ref.current.getBoundingClientRect().height // getBoundingClientRect
    );
    setHeight(nextHeight);
  };

  useEffect(() => {
    setActTab(getIndex());
  }, [props.location]);

  const getIndex = () => {
    const curUrl = props.location.pathname + props.location.search;
    for (let i = 0; i < props.items.length; i++) {
      const element = props.items[i];
      if (element.url === curUrl) {
        return i;
      } else if (element.url === "url") {
        const count = element.children?.find((ele) => ele.url == curUrl);
        if (count) return i;
      }
    }
    return null;
  };

  const bubleTop = () => {
    let top = 11;
    for (let i = 0; i < height.length; i++) {
      const e = height[i];
      if (getIndex() === i) {
        break;
      }
      top += e;
    }
    return top;
  };

  return (
    <div
      className={`new-sidebar ${props.isOpenNavBar ? "active" : "inactive"}`}
      style={{
        backgroundColor: myColor.linearG[props.color],
      }}
    >
      {props.items?.map((item, key) => (
        <div
          key={key}
          ref={elementsRef.current[key]}
          className={`sidebar-item ${!props.isOpenNavBar ? "hidding" : ""}`}
        >
          {!props.isOpenNavBar && (
            <span className="detail-navbar-item">{item.name}</span>
          )}
          {item.url === "url" ? (
            <DropDownNavBar
              icon={item.icon}
              name={item.name}
              items={item.children}
              color={props.color}
              isActive={key === actTab}
              setNewHeight={() => {
                setNewHeight();
              }}
              location={props.location}
              isOpenNavBar={props.isOpenNavBar}
            />
          ) : (
            <Link
              style={{
                position: "relative",
                zIndex: key === actTab ? "10" : "8",
                color:
                  key !== actTab ? "white" : `${myColor.fColor[props.color]}`,
                fontWeight: "600",
              }}
              className={!props.isOpenNavBar ? "center full-height" : ""}
              to={item.url}
              onClick={() => {
                setNewHeight();
              }}
            >
              <i
                className={`${item.icon} ${props.isOpenNavBar ? "mr-3" : ""}`}
              ></i>
              <span>{props.isOpenNavBar && item.name}</span>
            </Link>
          )}
        </div>
      ))}
      {getIndex() !== null && (
        <div
          style={{
            height: height[getIndex()],
            top: `${bubleTop()}px`,
          }}
          className="activeTab"
        />
      )}
    </div>
  );
};

export default SimpleNavBar;
