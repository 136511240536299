// import React, { useState, useEffect } from "react";
// import FileViewer from "react-file-viewer";


// // const type = "pdf";

// const onError = (e) => {
//     console.log(e, "error in file-viewer");
// };

// export default function FileViewerReact() {
//     const [file, setFile] = useState("");
//     const [type, setType] = useState("jpg");

//     useEffect(() => {
//         let file = localStorage.getItem("fileViewer")
//         let type = localStorage.getItem("typeFile")

//         setFile(file)
//         setFile(type)

//     }, [])
//     return (
//         <FileViewer fileType={type} filePath={file} onError={onError} key={file} />
//     );
// }


import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
// import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import i18next from "i18next"
import '@react-pdf-viewer/core/lib/styles/index.css';

// let file = localStorage.getItem("fileViewer")
// let type = localStorage.getItem("typeFile")

export default class MyComponent extends Component {
    constructor(props) {
        super(props)
    }
    render() {

        return (
            <Modal
                isOpen={this.props.isShow}
                fade={false}
                size={"lg"}
                backdrop={true}
                keyboard={false}
                toggle={() => this.props.handleClose()}
            >

                <ModalHeader>
                    <div className="pull-left">
                        <h4>{i18next.t('fileViewer')}</h4>
                    </div>
                    <div className="pull-right">
                        <a color="secondary"
                            onClick={() => this.props.handleClose()}
                        >
                            <i className="fa fa-times" />
                        </a>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/* {this.props.type !== 'pdf' && <img src={this.props.file} />}
                    {this.props.type === 'pdf' &&
                        <div style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '750px',
                            overflow: "auto"
                        }} >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js" />
                        <Viewer
                            fileUrl={this.props.file}
                            defaultScale={SpecialZoomLevel.PageWidth}
                        />
                        </div>} */}
                    <div style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '78vh',
                        overflow: "auto"
                    }} >
                        {
                            this.props.type === "csv" &&
                            <FileViewer
                                key={this.props.file}

                                fileType={this.props.type}
                                filePath={this.props.file}
                                // errorComponent={CustomErrorComponent}
                                onError={this.onError} />
                        }
                        {
                            (this.props.type !== "docx" && this.props.type !== "xlsx" && this.props.type !== 'csv') &&
                            <iframe src={this.props.file} frameborder="0" height="99%" width="100%">
                            </iframe>
                        }
                        {
                            (this.props.type === "docx" || this.props.type === "xlsx") &&
                            <iframe src={"https://docs.google.com/gview?url=" + this.props.file + "&embedded=true"} frameborder="0" height="99%" width="100%">
                            </iframe>
                        }
                    </div>
                </ModalBody>
            </Modal>

        );
    }

    onError(e) {
        console.log(e, 'error in file-viewer');
    }
}