import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import myColor from "../../../services/color";

export const DropDownNavBar = ({
  name,
  items,
  isActive,
  color,
  icon,
  setNewHeight,
  location,
  isOpenNavBar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
  }, [isOpenNavBar]);
  useEffect(() => {
    setNewHeight();
  }, [isOpen]);

  return (
    <div
      style={{
        zIndex: "10",
        color: !isActive ? "white" : `${myColor.fColor[color]}`,
      }}
      className="dropdown-navbar"
    >
      <div
        className={`dropdown-content ${!isOpenNavBar ? "hidding" : ""}`}
        onClick={() => {
          if (isOpenNavBar) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <i className={`${icon} ${isOpenNavBar && "mr-3"}`}></i>
        <span>{isOpenNavBar && name.trim()}</span>
        {isOpenNavBar && (
          <i
            className="icon-arrow-right float-right mt-1"
            style={{
              transform: isOpen && "rotate(90deg)",
              transition: "transform 0.3s",
            }}
          ></i>
        )}
      </div>

      <ul
        style={
          isOpenNavBar
            ? {
                height: isOpen ? `${items.length * 34}px` : "0",
                overflow: "hidden",
                paddingLeft: "30px",
              }
            : {}
        }
        className={!isOpenNavBar ? "list-dropdown-item" : ""}
      >
        {items.map((item, key) => (
          <li key={key} className="dropdown-navbar-item">
            <Link
              to={item.url}
              className={
                location.pathname + location.search === item.url
                ? "active"
                : "normal"
              }
              style={{
                color: !isActive && "white",
                height: "full-height",
                textAlign: "center",
              }}
            >
              {item.icon ? (
                <i className={item.icon + " mr-2"}></i>
              ) : (
                <span style={{ height: "2px", marginRight: "0.5rem" }}>
                  &#8210;
                </span>
              )}
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
