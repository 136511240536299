import React from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import request from '../services/request';
import api from '../services/api'
import helper from '../services/helper'
import imageCompression from 'browser-image-compression';
import i18next from 'i18next';
import { Progress } from 'rsuite';
const { Line } = Progress;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '500px'
    }
};

export default class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: -1,
            src: '',
            cropResult: props.value || null,
            schema: props.schema || {},
            hover: false,
            isLoading: false
        };
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    interval = null;
    async componentDidMount() {
        let config = await api.getConfig({ keys: ['IMAGE'] })
        if (config) {
            this.setState({ urlImage: config.data.IMAGE.subConfigs.URL_S3.value })
            let width = this.props.schema && Number(this.props.schema.imageWidth) || 250
            let height = this.props.schema && Number(this.props.schema.imageHeight) || 200
            if (this.props.promotion) {
                let widthHeight = config.data.IMAGE.subConfigs.IMAGE_PROMOTION.value
                // this.setState({ width: widthHeight.split(',')[0], height: widthHeight.split(',')[1] })
                width = widthHeight.split(',')[0]
                height = widthHeight.split(',')[1]
            }

            if (width > 500) {
                width = Math.round(width / 3)
                height = Math.round(height / 3)
                this.setState({ width, height })
            } else this.setState({ width, height })
        }
    }
    async componentWillReceiveProps(next) {
        await this.setState({
            cropResult: next.value || null,
            schema: next.schema || {},
            width: next.schema && Number(next.schema.imageWidth) || 250,
            height: next.schema && Number(next.schema.imageHeight) || 200
        })
        let { width, height } = this.state;
        if (width > 500) {
            width = Math.round(width / 3)
            height = Math.round(height / 3)
            this.setState({ width, height })
        }
    }
    onChange(e) {
        // e.preventDefault();
        this.setState({ progress: -1, status: "" })

        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ src: reader.result, showModal: true });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    async cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }
        await this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL(),
            showModal: false, isLoading: true, progress: 0
        });

        this.interval = setInterval(() => {
            if (this.state.progress < 95) {
                this.setState({ progress: this.state.progress + 1 });
            }
        }, 50)

        let img = this.DataURLtoFile(this.state.cropResult, 'image')
        console.log(`img size ${img.size / 1024 / 1024} MB`);
        const compressedFile = await imageCompression(img, {
            maxSizeMB: 1,
            useWebWorker: true
        });
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
        this.uploadFile(compressedFile);
    }
    async uploadFile(compressedFile) {
        try {
            const formData = new FormData();
            formData.append('image', compressedFile);
            // formData.append('size', JSON.stringify({ width: this.state.width, height: this.state.height }))


            let rs = await request.upload('/api/upload/uploadImage', formData, 'POST');
            if (rs && rs.errorCode === 0) {
                clearInterval(this.interval)
                this.setState({ progress: 100, status: "success" });
                if (this.props.onChange) {
                    this.props.onChange(rs.link);
                }
            }
        } catch (e) {
            this.setState({ progress: 99, status: "fail", cropResult: null });
            console.warn('error upload image ' + e);
            helper.toast('error', i18next.t('internalServerError'))
        }
    }
    DataURLtoFile = (dataUrl, filename) => {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    progressBar = () => {
        if (this.state.progress >= 0) {
            return <Line percent={this.state.progress} status={this.state.status} style={{ width: `${this.state.width}px` }} />
        }
    }

    render() {
        return (<>
            <div>
                <input type="file" accept="image/*"
                    disabled={this.props.schema && this.props.schema?.disabled}
                    onChange={this.onChange} style={{ display: 'none' }}
                    ref={fileInput => this.fileInput = fileInput} />
            </div>
            {this.state.showModal && (
                <Modal
                    isOpen={this.state.showModal}
                    style={customStyles}>
                    <h2>Crop Image</h2>
                    <div style={{ float: 'right', marginTop: '-9%' }}>
                        <Button className="btn btn-danger btn-sm" onClick={() => { this.setState({ showModal: false }) }}>Close</Button>
                        <Button style={{ marginLeft: '5px' }} className="btn btn-success btn-sm" onClick={this.cropImage}>Crop</Button>
                    </div>
                    <hr />
                    <Cropper
                        style={{ height: '100%', width: '100%' }}
                        aspectRatio={this.state.width / this.state.height}
                        preview=".img-preview"
                        guides={false}
                        src={this.state.src}
                        ref={cropper => {
                            this.cropper = cropper;
                        }}
                        viewMode={1}
                        dragMode="move"
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        scalable={false}
                    />
                </Modal>
            )}
            {!this.state.cropResult && (
                <i className="fa fa-upload fa-3x"
                    style={{ position: 'absolute', top: '40%', left: this.props.left || '12%' }}
                    onClick={() => this.fileInput.click()}></i>
            )}
            {this.state.cropResult && (
                <i className="fa fa-upload fa-2x"
                    style={{ position: 'absolute', color: 'green', top: '9px', left: this.props.left || '4%', cursor: 'pointer' }}
                    onClick={() => this.fileInput.click()}></i>
            )}
            <img className='file-picker-thumbnail' style={{
                    width: `${this.state.width}px`,
                    height: `${this.state.height}px`,
                    marginTop: '10px'
                }}
                // onMouseLeave={() => { this.setState({ hover: false }) }}
                // onMouseOver={() => { this.setState({ hover: true }) }}
                src={this.state.cropResult && this.state.cropResult.includes('upload/') ? this.state.urlImage + this.state.cropResult : this.state.cropResult}
                onClick={() => {
                    if (!this.state.cropResult) this.fileInput.click()
                }} />
            {this.progressBar()}
        </>)
    }
}
