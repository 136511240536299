import React, { Component } from 'react';
import request from '../services/request';
import { connect } from 'react-redux';
import helper from '../services/helper';
import FileViewer from "../views/Company/FileViewer/FileViewer"
class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: props.width,
            height: props.height,
            showAction: false,
            isShowModal: false,

        };
        this.actions = [{ label: 'View', icon: "fa fa-rocket" },
        { label: 'Download', icon: "fa fa-download" },
        { label: "Delete", icon: "fa fa-trash" }]

    }


    async uploadFile(file) {
        try {
            // this.props.onChange("batch/IMG_0184_44ed39c5-86a1-4024-8589-950eb988aa36.JPG")

            let formData = new FormData();
            formData.append('file', file);
            let rs = await request.upload('/api/upload/uploadFile', formData, 'POST')
            if (rs && rs.errorCode === 0) {
                if (this.props.onChange) {
                    this.props.onChange(rs.data.data);
                }
            }
        } catch (err) {
            helper.toast('error', err.message);
        }
    }
    changeTitle(str) {
        const words = str.split('/');
        return words[words.length - 1];
    }
    onClickClose(e) {
        if (!this.props.schema.disabled)
            this.fileInput.click()
    }
    onClickOpt(idx) {
        let filePath = this.props.config.AMAZONE_S3.subConfigs.LINK_STATIC.value +
            this.props.value
        if (idx === 0) {

            this.setState({ isShowModal: true, file: filePath, type: filePath.split('.').pop() })
        } else if (idx === 1) {  //downLoad
            window.open(filePath, '_blank');
        } else if (idx === 2 && this.props.onChange) { //del          
            this.setState({ showAction: false })
            this.props.onChange("");
        }
    }
    render() {
        let { showAction, isShowModal, file, type } = this.state
        let disabled = this.props.schema.disabled, style = "m-1 p-1 "
        if (disabled) style += "not-allowed"
        else style += "pointer"
        return (
            <>
                {isShowModal &&
                    <FileViewer isShow={isShowModal} file={file} type={type}
                        handleClose={() => this.setState({ isShowModal: false })} />
                }
                <div className="d-flex">
                    <div className={style} style={{ border: "1px solid #d0d3d4" }}
                        onClick={(e) => this.onClickClose(e)} >
                        <i className="fa fa-paperclip mr-1" />
                        <b>Upload Attachment</b>
                    </div>
                    <input type="file"
                        ref={fileInput => this.fileInput = fileInput}
                        className="display-none"
                        disabled={this.props.disabled}
                        onChange={evt => {
                            this.uploadFile(evt.target.files[0]);
                        }} />
                    <div className="m-1">
                        {this.props.value &&
                            <i className="fa fa-times icon-rm-options"
                                title="Remove file"
                                onClick={() => {
                                    if (this.props.onChange) {
                                        this.props.onChange("");
                                    }
                                }} />
                        }
                    </div>
                </div>
                {this.props.value &&
                    <div className="m-1 p-1" style={{ border: "1px solid #d0d3d4" }}>
                        <i className="fa fa-file mr-1" />
                        {this.changeTitle(this.props.value)}
                        <i className="fa fa-chevron-down pull-right"
                            onClick={() => this.setState({ showAction: !showAction })} />
                    </div>

                }
                {showAction && <div className="uploadWF-action">
                    {this.actions.map((act, i) => (<div className="pointer"
                        onClick={() => this.onClickOpt(i)} key={i}>
                        <i className={act.icon + " m-1"} />
                        {act.label}
                    </div>
                    ))}
                </div>
                }
            </>
        )
    }
}
const bindState = state => ({
    config: state.userInfo.configs
});

export default connect(bindState)(Upload);