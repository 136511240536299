import React, { Component } from 'react';
import { Input } from 'reactstrap';
class TextArea extends Component {
    render() {
        return <Input
            disabled={this.props.schema.disabled}
            rows={5}
            type='textarea'
            invalid={this.props.invalid || false}
            placeholder={this.props.schema.placeholder}
            value={this.props.value}
            onChange={evt => {
                if (this.props.onChange) {
                    this.props.onChange(evt.target.value);
                }
            }} />
    }
}

export default TextArea;