import React from 'react';
import api from '../services/api';
import helper from '../services/helper';
import i18next from 'i18next';
import SelectMultiple from '@khanacademy/react-multi-select';


export default class UserField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema || {},
            data: []
        }
    }
    UNSAFE_componentWillMount() {
        this.fetchData();
    }
    UNSAFE_componentWillReceiveProps(nxtProps) {

    }
    async fetchData() {
        try {
            let { schema } = this.state;
            let { filter = {} } = schema;

            let result = await api.queryUserField(filter);

            let data = result && result.data || []

            data.forEach(item => {
                helper.renameKey(item, 'sid', 'value')
                helper.renameKey(item, 'name', 'label')
            })

            this.setState({ data })
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        let { schema, data } = this.state;

        return (<>
            <SelectMultiple options={data}
                selected={this.props.value || []} onSelectedChanged={val => {
                    if (this.props.onChange) {
                        this.props.onChange(val)
                    }
                }} />
        </>)
    }
}