import request from "./request";

var config = {};

var path = {
  //login
  login: "/admin/user/loginWithPassword",
  // login: "/user/loginWithPwd",
  logout: "/api/user/logout",
  getCaptcha: "/api/user/create-captcha",
  getMeta: "/api/admin/get-meta",
  refreshToken: "/user/refreshToken",

  getLanguage: "/language/getList",
  getConfig: "/config/getConfig",

  createRole: "/role/create",
  updateRole: "/role/update",
  getListRoleByCompany: "/role/listRoleByCompany",
  findPermissionPage: "/permission/findPerPage",
  savePermission: "/admin/permission/save",
  checkPermissionButton: "/permission/checkPerButton",
  getListPermission: "/permission/getList",

  changePassword: "/user/changePass",
  updateProfile: "/user/updateProfile",
  // api Definition
  saveApiDefinition: "/api/saveApiDefinition",
  updateApiDefinition: "/api/updateApiDefinition",
  getMenu: "/api/menu/getMenu",
  getListService: "/service/getListService",
  createGroupUser: "/userGroup/create",
  findUserGroup: "/userGroup/findOne",
  updateGroupUser: "/userGroup/update",
  getUserGroupByCompany: "/admin/userGroup/getListByCompany",
  getAllNtf: "/api/ntf/getAll",

  detailService: "/admin/service/detail",
  createService: "/admin/service/create",
  updateService: "/admin/service/update",
  deleteUserGroup: "/userGroup/delete",
  configurationCompany: "/admin/company/configuration",
  getSettingCompany: "/admin/company/getSetting",
  getInfo: "/user/getInfo",

  detailTask: "/company/task/detail",
  rejectTransaction: "/company/event/reject",
  getListUsers: "/user/listUsers",
  checkPermCrtNewTrans: "/event/checkPermCrtNewTrans",
  createEvent: "/event/create",
  approveTransaction: "/event/approveTransaction",
  listBankAccount: "/admin/listBankAccount",
  getNotiByChannel: "/user/listNotiByChannel",
  updateIsNewNoti: "/user/updateIsNewNoti",
  markNotiAsRead: "/user/markNotiAsRead",
  getToDo: "/company/task/gettodo",
  getCreatedByTask: "/company/task/getcreatedby",
  getCreatedByApp: "/company/task/getcreatedbyapp",
  getRecentActivities: "/admin/activity/getrecentactivities",
  markAllAsRead: "/user/markAllAsRead",
  getAllNtf: "/api/ntf/getAll",
  adminGetMenuNPage: "/admin/getMenuNPage",

  getAuthenticationSrv: "/service/getAuthenticationSrv",

  //TamDV 25/12/2020
  getCorporateProfile: "/admin/getCorporateProfile",

  signOtpCreateTrans: "/otp/signOtpCreateTrans",

  //TamDV 28/12/2020
  signOtpApproveOrRefusedTrans: "/otp/signOtpApproveOrRefusedTrans",

  //TamDV 12/01/2021
  getTransactionNum: "/subevent/getTransactionNum",
  getListSubEvent: "/subEvent/getList",

  // testPush: "/web/push/test"
  //hieupq 12/01/2021
  getListCashCode: "/admin/getListCashCode",
  getLinkCashCode: "/admin/getLinkCashCode",

  configurationEventForCompany: "/admin/company/configurationEvent",
  //TamDV
  getConfigWebpush: "/config/getConfigWebpush",
  //TamDV
  createProcess: "/workFlow/process/create",
  createSection: "/workFlow/section/create", // not used

  //anhnbt
  getStepSchema: "/progress/item/get",
  //Quanlh
  getSections: "/workFlow/section/getSections", // not used
  updatePermission: "/workflow/step/updatePermission", // not used
  //hieupq
  saveSteps: "/workFlow/flow/saveSteps", // not used
  getUsersByCompany: "/user/getUsersByCompany",
  //TamDV
  publishProcess: "/workFlow/process/publish",

  //Quanlh
  getProcessBySid: "/workFlow/process/getProcessBySid",
  //anhnbt
  getStepInOrder: "/workFlow/flow/getStepInOrder",
  getFlow: "/admin/flow/getFlow",
  getStepInProcess: "/workFlow/flow/getStepsInProcess",

  //tamDv: 12/03
  getWorkFlow: "/workFlow/process/getWorkFlow",
  updateSections: "/workFlow/section/update", // not used

  //tamDv: 15/03
  getStepsWithSections: "/workflow/step/getStepsWithSections",

  //tamDv: 18/03
  createFlow: "/workflow/engine/createFlow",
  submitFlow: "/workflow/engine/submitFlow",
  //hieupq 18/3
  getAllSteps: "/workFlow/flow/getAllSteps", // not used
  getFunctions: "/workFlow/function/getFunctions",
  //tamDv 19/06
  getPendingFlows: "/admin/flow/getPendingTasks",
  WFUserAction: "/workflow/engine/userAction",

  //tamDv 22/03:
  listFunction: "/workFlow/function/getFunctions",

  //tamDv 23/03:
  getFunctionBySid: "/workFlow/function/getFunctionBySid",
  //tamDv 29/03:
  getFetchApis: "/workFlow/WFFetchApi/getFunctions",
  getFetchApiBySid: "/workFlow/WFFetchApi/getFunctionBySid",
  //tamDv 30/03:
  listMyItems: "/admin/flow/listMyItems",
  getMyTasks: "/admin/flow/getMyTasks",

  //hieupq 1/4
  uploadDataTable: "/workflow/DTableRow/uploadDataTable",
  getDataTable: "/workflow/DTableRow/getDataTable",

  //
  checkFlowAuth: "/workflow/engine/checkFlowAuth",

  queryUserField: "/workflow/section/queryUserField",
  //hieupq
  saveProcess: "/workFlow/process/save",
  getSteps: "/workFlow/flow/getSteps",

  //tamDV 13/04
  getAllVersion: "/workFlow/process/getAllVersion",

  //TamDV 29/04
  signUpWithGoogle: "/auth/google/signUp",

  // TamDV 07/05
  getDataSets: "/dataSet/getList",
  getCollection: "/dataSet/findCollection",

  // TamDV 18/05
  getMyTasksByProcessId: "/flow/getMyTasksByProcessId",

  getFlowByProcessVersion: "/admin/flow/getFlowByProcessVersion",
  //hieuPQ 03/06
  createProcessTemplate: "/workFlow/processTemplate/createTemplate",
  getWorkFlowTemplate: "/workFlow/processTemplate/getWorkFlowTemplate",
  getAllVersionTemplate: "/workFlow/processTemplate/getAllVersionTemplate",
  publishProcessTemplate: "/workFlow/processTemplate/publishTemplate",
  saveProcessTemplate: "/workFlow/processTemplate/saveTemplate",
  getAllProcessTemplate: "/workFlow/processTemplate/getAllProcessTemplate",

  // TamDV
  sendComment: "/flow/createComment",
  getCommentByFlow: "/flow/getCommentByFlow",

  //hieupq
  setPublicForm: "/workFlow/publicForm/setPublicForm",
  getPublicForm: "/workFlow/publicForm/getPublicForm",
  getForm: "/form/getForm",
  getDataList: "/dataList/getDataList",
  getDataListCollection: "/dataList/getDataListCollection",

  // TamDV
  reqForgotPassword: "/user/reqForgotPassword",
  checkTokenForgotPWD: "/user/checkTokenForgotPWD",
  verifyPassword: "/user/verifyPassword",

  //Tamdv 01/07/1999

  submitPublicForm: "/workflow/publicForm/submitRequest",

  countSubEventWithStatus: "/subEvent/countWithStatus",

  listAllMyItems: "/admin/flow/listAllMyItems",
  createCorEmployee: "/user/createCorEmployee",
  updateCorEmployee: "/user/updateCorEmployee",
  deleteCorEmployee: "/user/deleteCorEmployee",
  findCorEmployee: "/user/findCorEmployee",
  exportCorEmployee: "/user/exportCorEmployee",
  listTaskQueue: "/user/task/listTaskQueue",
  getDepartment: "/user/ddepartment/getAll",

  checkCorporateAccount: "/ewa/checkCorporateAccount",
  loanUnitEWAList: "/ewa/loanUnitEWAList",
  requestRepayment: "/ewa/requestRepayment",
  getDetailEWA: "/ewa/getDetailEWA",
  exportListDisbursement: "/ewa/exportListDisbursement",
  exportDetailEWA: "/ewa/exportDetailEWA",

  doHttpRequest: "/httpRequest/doHttpRequest",
};

Object.keys(path).forEach(function (key) {
  config[key] = async function (data) {
    let result = await request.request(path[key], data);
    return result;
  };
}, this);

export default config;
