import React, { Component } from 'react';
import i18next from 'i18next';
import Select from 'react-select'
import { Table, Input, Button } from 'reactstrap'
import api from '../services/api'
import helper from '../services/helper'
import _ from 'lodash'

export default class TableCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            lsEmployee: []
        };
    }
    componentDidMount() {
        this.init()
    }
    componentWillReceiveProps(next) {
        let value = next.value || []
        value.map(item => {
            if (item.sid || item.name) {
                this.renameKey(item, 'sid', 'key')
                this.renameKey(item, 'name', 'label')
            }
        })
        this.setState({ value: value || [] })
    }
    async init() {
        try {
            let result = await api.lsEmployee({ select: ['name', 'email', 'phone', 'sid'] })
            result.data.map(obj => {
                this.renameKey(obj, 'sid', 'key')
                this.renameKey(obj, 'name', 'label')
            })
            await this.setState({ lsEmployee: result.data })
        } catch (e) {
            helper.toast('error', e.errorMsg || 'error')
        }
    }
    async saveData(val, index) {
        let value = _.clone(this.state.value);
        value[index] = val
        this.setState({ value })
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
    renameKey(obj, old_key, new_key) {
        if (old_key !== new_key) {
            Object.defineProperty(obj, new_key, // modify old key 
                // fetch description from object 
                Object.getOwnPropertyDescriptor(obj, old_key));
            delete obj[old_key];                // delete old key 
        }
    }
    addItem() {
        let value = _.clone(this.state.value);
        value.push('')
        this.setState({ value: value })
    }
    removeItem(index) {
        let value = _.clone(this.state.value);
        value.splice(index, 1);
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <Table hober bordered>
                <thead>
                    <tr>
                        <th width='50'>{i18next.t('no')}</th>
                        <th width='190'>{i18next.t('name')}</th>
                        <th>{i18next.t('phone')}</th>
                        <th>{i18next.t('email')}</th>
                        <th style={{ width: '50px' }}><Button className="btn btn-default btn-sm"
                            onClick={this.addItem.bind(this)}><i className="fa fa-plus"></i></Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.value.map((item, index) => {
                            return <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td><Select options={this.state.lsEmployee}
                                    value={item}
                                    onChange={e => { this.saveData(e, index) }} />
                                </td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td><Button className="btn btn-danger btn-sm" onClick={e => { this.removeItem(index) }}><i className="fa fa-trash"></i></Button></td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        )
    }
}   