import DeviceDetector from "device-detector-js";
import i18next from "i18next";
import queryString from "qs";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
// import { CloseRounded } from "@material-ui/icons";
import logo from "../../assets/img/brand/asyst.png";
import Loader from "../../controls/Loader";
import api from "../../services/api.js";
import Config from "../../services/config";
import helper from "../../services/helper";
import local from "../../services/local.js";
import "./style.scss";

const deviceDetector = new DeviceDetector();

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: true,
      captchaId: 0,
      captchaText: "",
      captcha: null,
      accountKitToken: "",
      disableLogin: false,
      isShowModalVerify: false,
      captchaVerify: { data: null },
      isShowOTPVerifyText: false,
      showPassword: false,
    };
  }
  componentDidMount() {
    this.loadCaptcha();

    this.query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (this.query.data) {
      var data = JSON.parse(this.query.data);
      local.set("session", data.token);
      local.set("user", JSON.stringify(data.userInfo));
      this.props.history.push(`/dashboard`);
      console.log(this.query);
    }
  }

  async loadCaptcha(type = "") {
    try {
      let captInfo = await api.getCaptcha();
      if (type === "verify") {
        this.setState({
          captchaVerify: { captchaId: captInfo.id, data: captInfo.data },
        });
      } else
        this.setState({
          loading: false,
          captchaId: captInfo.id,
          captcha: captInfo.data,
        });
    } catch (e) { }
  }
  async onLoginClick(e) {
    e.preventDefault();
    try {
      this.setState({ disableLogin: true });
      let url = `${Config.host}/admin/user/loginWithPassword`;
      let option = {
        method: "POST", // or 'PUT'
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
          captchaId: this.state.captchaId,
          captchaText: this.state.captchaText,
        }), // data can be `string` or {object}!
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Basic ${Config.BASIC_TOKEN}`,
          device: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
          subscription: localStorage.getItem("subscription") || null,
        },
      };

      let res = await fetch(url, option);
      let rs = await res.json();
      if (rs && rs.data && rs.data.token) {
        local.set("session", rs.data.token);
        local.set("user", JSON.stringify(rs.data.userInfo));
        this.props.history.push(`/dashboard`);
      } else if (rs && rs.data && rs.data.otp) {
        this.loadCaptcha("verify");
        this.setState({ userId: rs.data.user, otpId: rs.data.otpId });
        this.setState({ isShowModalVerify: true });
      } else {
        this.loadCaptcha();
        this.setState({ captchaText: "", disableLogin: false });
        helper.toast("error", rs.errorMsg);
      }
    } catch (err) {
      console.log("loadCaptcha", err);
      this.loadCaptcha();
      this.setState({ captchaText: "", disableLogin: false });
    }
  }

  async handleVerify() {
    try {
      this.setState({ disableLogin: true });
      let url = `${Config.host}/admin/user/verifyLogin`;
      let option = {
        method: "POST", // or 'PUT'
        body: JSON.stringify({
          otp: this.state.otp,
          otpId: this.state.otpId,
          userId: this.state.userId,
          captchaId: this.state.captchaVerify.captchaId,
          captchaText: this.state.captchaVerify.captchaText,
          username: this.state.username,
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Basic ${Config.BASIC_TOKEN}`,
          device: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
          subscription: localStorage.getItem("subscription") || null,
        },
      };

      let res = await fetch(url, option);
      let rs = await res.json();
      if (rs && rs.data && rs.data.token) {
        local.set("session", rs.data.token);
        local.set("user", JSON.stringify(rs.data.userInfo));
        this.props.history.push(`/dashboard`);
        // } else {
        //   helper.toast('error', 'Account is not valid.')
        //   this.loadCaptcha()
        // }
      } else if (rs && rs.data && rs.data.otp) {
        this.loadCaptcha("verify");
        this.setState({ isShowModalVerify: true });
      } else {
        this.loadCaptcha("verify");
        this.setState({ captchaText: "", disableLogin: false });
        helper.toast("error", rs.errorMsg);
      }
    } catch (err) {
      console.log("loadCaptcha", err);
      this.loadCaptcha("verify");
      this.setState({ captchaText: "", disableLogin: false });
    }
  }
  async handleAccountKitResponse(result) {
    console.log("on token", result);
    this.setState({ accountKitToken: result.code });
  }
  toggle = (isDel) => {
    this.loadCaptcha();
    this.setState({ isShowModalVerify: false,disableLogin: false })
  };

  forgotPassword = () => {
    this.props.history.push("/forgotPassword");
  };

  render() {
    let {
      disableLogin,
      isShowModalVerify,
      captchaVerify,
      otp,
      isShowOTPVerifyText,
    } = this.state;
    if (this.state.loading) return <Loader />;
    return (
      <div className="login-form">
        <div class="my-container">
          <div className="background-container">
            {
              <Modal
                isOpen={isShowModalVerify}
                fade={false}
                backdrop={true}
                keyboard={false}
                toggle={() => this.toggle()}
                centered={true}
              >
                <ModalBody className="modal-verify">
                  <Row>
                    <Col
                      md={12}
                      className="d-flex flex-column justify-content-center align-items-center mb-5"
                    >
                      <img
                        src="assets/img/shield-checkmark.png"
                        alt="verify"
                        className="img-fluid"
                        style={{ height: "60px" }}
                      />
                      <span style={{ fontSize: "25px", fontWeight: "700" }}>
                        {i18next.t("Verify Login")}
                      </span>
                    </Col>
                    <Col md={6}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <div
                            className="captcha"
                            dangerouslySetInnerHTML={{
                              __html: captchaVerify.data,
                            }}
                          ></div>
                          <Button
                            type="button"
                            onClick={() => {
                              this.loadCaptcha("verify");
                            }}
                            color="dark"
                          >
                            <img
                              className="img-fluid"
                              src="assets/img/refresh_captcha_verify.png"
                              alt=""
                            />
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <Input
                            type="text"
                            placeholder={i18next.t("Enter Captcha")}
                            value={captchaVerify.captchaText}
                            onChange={(evt) => {
                              captchaVerify.captchaText = evt.target.value;
                              this.setState({ captchaVerify });
                            }}
                            className="modal-input"
                          />
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>

                    {isShowOTPVerifyText && (
                      <Col md={12} className="mb-1">
                        <span
                          className="mb-1"
                          style={{ color: "#4DB749", fontWeight: "400" }}
                        >
                          We will send an OTP verification code to your email.
                        </span>
                      </Col>
                    )}

                    <Col md={12}>
                      <Input
                        type="text"
                        placeholder={i18next.t("Please Enter OTP")}
                        value={otp}
                        onChange={(evt) =>
                          this.setState({ otp: evt.target.value })
                        }
                        onFocus={() =>
                          this.setState({ isShowOTPVerifyText: true })
                        }
                        onBlur={() =>
                          this.setState({ isShowOTPVerifyText: false })
                        }
                        className="modal-input"
                      ></Input>
                    </Col>
                  </Row>
                  <button
                    className="mt-3 btn-success"
                    onClick={() => this.handleVerify()}
                  >
                    {i18next.t("ok")}
                  </button>
                  <span
                    className="btn-close"
                    onClick={() => this.setState({ isShowModalVerify: false })}
                  >
                    {/* <CloseRounded /> */}
                  </span>
                </ModalBody>
              </Modal>
            }

            <div className="my-container">
              <div className="background-login">
                <Card>
                  <CardBody>
                    <Row>
                      <img className="img-fluid" src={logo} alt="" />
                    </Row>
                    <Row>
                      <p className="title">{i18next.t("welcomeasyst")}</p>
                    </Row>
                    <Row>
                      {/* <p className="content">{i18next.t("asystcontent")}</p> */}
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div className="container-login">
                <Card className="login-card">
                  <CardBody>
                    <Row className="mb-5">
                      <Col md={12} style={{ textAlign: "center" }}>
                        <img
                          src="assets/img/logo_login.png"
                          className="login-logo"
                          alt="avatar"
                        />
                      </Col>
                    </Row>
                    <form
                      onSubmit={this.onLoginClick.bind(this)}
                      autoComplete="off"
                    >
                      <InputGroup className="mb-3">
                        <Input
                          type="text"
                          placeholder={i18next.t("Username")}
                          value={this.state.username}
                          onChange={(evt) =>
                            this.setState({ username: evt.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          type={this.state.showPassword ? "text" : "password"}
                          placeholder={i18next.t("password")}
                          value={this.state.password}
                          autoComplete="off"
                          onChange={(evt) =>
                            this.setState({ password: evt.target.value })
                          }
                        />
                        {this.state.password && (
                          <i
                            className={
                              this.state.showPassword
                                ? "fa fa-eye-slash eye-icon"
                                : "fa fa-eye eye-icon"
                            }
                            onClick={() => {
                              this.setState({
                                showPassword: !this.state.showPassword,
                              });
                            }}
                          ></i>
                        )}
                      </InputGroup>

                      <Row className="mb-5 captcha-box">
                        <Col md={6}>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <div
                                className="captcha"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.captcha,
                                }}
                              ></div>
                              <Button
                                className="btn-refresh"
                                color="light"
                                type="button"
                                onClick={() => {
                                  this.loadCaptcha();
                                }}
                              >
                                <img
                                  className="img-fluid"
                                  src="assets/img/refresh_captcha.png"
                                  alt=""
                                />
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col md={6}>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <Input
                                type="text"
                                className="captcha_input"
                                placeholder={i18next.t("Enter Captcha")}
                                value={this.state.captchaText}
                                onChange={(evt) =>
                                  this.setState({
                                    captchaText: evt.target.value,
                                  })
                                }
                              />
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mb-2 remember">
                        <Col md={6}>
                          <InputGroup className="remember-group">
                            <Input type="checkbox" id="remember-cb" color="success" />
                            <Label for="remember-cb">
                              {i18next.t("rememberme")}
                            </Label>
                          </InputGroup>
                        </Col>
                        <Col md={6}>
                          <p
                            id="forgotPass"
                            onClick={() => {
                              this.forgotPassword();
                            }}
                          >
                            {i18next.t("forgotPassword")}?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Button
                            color="primary"
                            type="submit"
                            className="login-btn"
                            block
                            disabled={disableLogin}
                          >
                            {i18next.t("login")}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewLogin;
