import React, { Component } from 'react';
import CurrencyInput from "react-currency-input";

class Currency extends Component {

    render() {
        let { schema, onChange } = this.props;
        let { currency } = schema

        return <CurrencyInput
            className="boderLine form-control"
            prefix={currency.prefix + " " || ""}
            value={this.props.value || ''}
            disabled={schema.disabled || false}
            onChangeEvent={(event, value, floatValue) => {
                if (onChange) {
                    onChange(value);
                }
            }}
            decimalSeparator={currency.decimalSeparator || "."}
            thousandSeparator={currency.thousandSeparator || ","}
            decimalplace={(currency.decimalPlace || 0) + ""}
        />
    }
}

export default Currency;