import React, { Component } from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarBrand,
  Label,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react"
// import logo from '/assets/img/logo.png'
import local from "../../services/local"
import api from "../../services/api"
import Image from "../../schema/Image"
import i18n from "../../i18n/i18n"
import Moment from "react-moment"
import ApproveTask from "../../views/Company/MyTask/ApproveTask"
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap"
import helper from "../../services/helper"
import i18next from "i18next"
import { metadata } from "core-js/fn/reflect"

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

let fileInput
class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: props.notifications || [],
      newNum: props.newNum || 0,
      count: props.count,
      isShowMore: false,
      skip: 0,
      limit: 10,
      userInfo: local.get("user"),
      isShowModalLogout: false,
    }
    this.onClickSeeMore = this.onClickSeeMore.bind(this)
  }
  onLogoutClick() {
    this.setState({ isShowModalLogout: true })
    // window.location.href = '';
    // api.logout();
    // local.clear();
  }
  handleModelLogout() {
    window.location.href = ""
    api.logout()
    local.clear()
    this.setState({ isShowModalLogout: false })
  }
  handleModelCancelLogout() {
    this.setState({ isShowModalLogout: false })
  }
  // componentWillReceiveProps(nextProps) {
  //   if (this.props != nextProps) {
  //     this.setState({
  //       notifications: nextProps.notifications || [],
  //       newNum: nextProps.newNum || 0,
  //       count: nextProps.count,
  //       isShowModal: false
  //     })
  //   }
  // }
  // async changeLanguage(lng) {
  //   alert(lng)
  //   i18n.changeLanguage(lng);
  // }
  async onClickBell() {
    await this.setState({ isShowMore: false, newNum: 0 })
    if (this.props.onClickBell) {
      await this.props.onClickBell()
      this.props.onClickSeeMore(0, 6).then((rs) => {
        this.setState({ notifications: rs.notifications, newNum: rs.newNum })
      })
    }
  }
  async onClickSeeMore() {
    let { isShowMore, skip, limit, notifications } = this.state
    if (this.props.onClickSeeMore && isShowMore) {
      skip += 10
      let rs = await this.props.onClickSeeMore(skip, limit)
      notifications = notifications.concat(rs.notifications)
      await this.setState({
        notifications: notifications,
        newNum: rs.newNum,
        skip,
      })
    } else {
      let rs = await this.props.onClickSeeMore(skip, limit)
      await this.setState({
        notifications: rs.notifications,
        newNum: rs.newNum,
        isShowMore: true,
      })
    }
  }
  onScroll = (event) => {
    const scrollY = event.target.scrollTop + event.target.clientHeight
    const scrollHeight = event.target.scrollHeight
    if (scrollY == scrollHeight) {
      this.onClickSeeMore()
    }
  };
  onClickClose(e) {
    this.fileInput.onClick(e)
    this.setState({ isShowMore: false })
  }
  async onClickDeatail(noti, idx) {
    if (noti.metadata && noti.metadata.stepsHistoryId) {
      this.props.history.push("newItem?sid=" + noti.metadata.stepsHistoryId)
    } else {
      let modalQuery = {},
        isShowModal = true
      let { notifications } = this.state
      modalQuery.sid = noti.cmd.requestId
      if (!noti.userRead) {
        let rs = await api.markNotiAsRead({ sid: noti.sid, user: noti.user })
        if (rs && rs.errorCode === 0) {
          notifications[idx] = rs.data
        }
      }
      this.setState({ modalQuery, isShowModal, notifications })
    }

  }
  async onMarkAllAsRead(e) {
    try {
      await api.markAllAsRead({ channel: "push" })
      this.onClickClose(e)
      // let { skip, limit } = this.state
      // let rs = await this.props.onClickSeeMore(skip, limit);
      this.setState({ skip: 0 })
    } catch (error) {
      console.log("err: " + error.stack)
    }
  }

  handleAvatar(userInfo) {
    const AVATAR_DEFAULT = "https://www.kindpng.com/picc/m/130-1300217_user-icon-member-icon-png-transparent-png.png"
    let avatar = AVATAR_DEFAULT
    let { IMAGE } = this.props.userInfo.configs
    if (userInfo.avatar && IMAGE?.subConfigs?.URL_S3?.value) {
      avatar = IMAGE.subConfigs.URL_S3.value + userInfo.avatar
      if (!avatar.includes("http")) {
        avatar = AVATAR_DEFAULT
      }
    }

    return avatar
  }

  render() {
    // eslint-disable-next-line
    let { notifications, newNum, isShowMore, userInfo, isShowModalLogout } =
      this.state
    let ddNoti = "noti"
    if (isShowMore) {
      ddNoti = "notiAll"
    }
    const { children, ...attributes } = this.props
    // console.log(isShowModalLogout);

    return (
      <React.Fragment>
        {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}

        <NavbarBrand className="appnavbarName">Business Solution </NavbarBrand>
        {/* <AppSidebarToggler
          className="d-md-down-none appnavbarName"
          display="lg"
        /> */}
        <i className='icon-menu' onClick={() => { this.props.onTogglerNavBar() }} />
        <Modal
          isOpen={this.state.isShowModal}
          fade={false}
          size={"lg"}
          backdrop={true}
          keyboard={false}
        >
          <ModalHeader>
            <div className="pull-left">
              <h4>{i18next.t("detailTask")}</h4>
            </div>
            <div className="pull-right">
              <a
                color="secondary"
                onClick={() => {
                  this.setState({ isShowModal: false })
                }}
              >
                <i className="fa fa-times" />
              </a>
            </div>
          </ModalHeader>
          <ModalBody>
            {this.state.isShowModal && (
              <ApproveTask
                query={this.state.modalQuery}
                showModal={this.state.isShowModal}
                onCloseModal={() => {
                  this.setState({ isShowModal: false })
                }}
                onChange={(data) => {
                  this.setState({ isShowModal: false })
                  helper.toast("success", data.message)
                }}
                userInfo={userInfo}
              />
            )}
          </ModalBody>
        </Modal>

        <Nav className="ml-auto" navbar>
          {/* tamDv 11/06
           <Link className="nav-item nav-link " to='/categories'>
            <i className="fa fa-th font-x-large pt-2" />
          </Link> */}
          {userInfo.client === "user" && userInfo.userGroup && (
            <Label
              className="bold mb-0 user-company"
              style={{ textTransform: "capitalize" }}
            >
              {userInfo.userGroup.name}
            </Label>
          )}
          {userInfo.client === "user" && (
            <AppHeaderDropdown
              direction="down"
              onScroll={(e) => this.onScroll(e)}
            >
              <DropdownToggle nav ref={(file) => (this.fileInput = file)}>
                {newNum != 0 && <span className="noti-badge">{newNum}</span>}
                <i
                  className="fa fa-bell-o img-avatar font-x-large pt-2"
                  onClick={() => this.onClickBell()}
                />
              </DropdownToggle>
              <DropdownMenu right className={ddNoti}>
                <div className="noti-header">
                  <div className="noti-header-text">notifications </div>
                  {notifications.length >= 6 ? (
                    <div
                      className="pull-right kb-bd-time kb-bd-title"
                      onClick={(e) => this.onMarkAllAsRead(e)}
                    >
                      Mark all as read
                    </div>
                  ) : (
                    <div
                      className="pull-right kb-bd-time kb-bd-title"
                      onClick={(e) => this.onClickClose(e)}
                    >
                      Close
                    </div>
                  )}
                </div>

                {notifications.map((noti, idx) => {
                  if (noti.notifyFor === "event") {
                    let styleItem = "noti-item  noti-isNew",
                      styleTime = "kb-bd-time noti-time noti-isNew"
                    let styleIcon = " bell-isNew"
                    if (noti.icon) {
                      noti.icon += " noti-icon"
                    } else noti.icon = "fa fa-bell-o noti-icon"
                    if (noti.userRead && noti.userRead.id) {
                      styleItem = "noti-item "
                      styleTime = "kb-bd-time noti-time"
                      styleIcon = ""
                    }
                    return (
                      <DropdownItem
                        className={styleItem}
                        key={idx}
                        onClick={() => this.onClickDeatail(noti, idx)}
                      >
                        <i className={noti.icon + styleIcon} />
                        <div>
                          <div className="noti-content">
                            <b>{noti.title}</b>
                            &nbsp;{noti.content.en}
                          </div>
                          <div className={styleTime}>
                            <Moment toNow>{new Date(noti.createdAt)}</Moment>
                          </div>
                        </div>
                      </DropdownItem>
                    )
                  }
                })}
              </DropdownMenu>
            </AppHeaderDropdown>
          )}

          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img
                className="img-avatar"
                style={{ width: "35px", height: "35px" }}
                src={this.handleAvatar(userInfo)}
                alt="Card image cap"
              />
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem tag={Link} to="/profile">
                <i className="fa fa-user" />
                Profile
              </DropdownItem>

              <DropdownItem onClick={this.onLogoutClick.bind(this)}>
                <i className="fa fa-lock" />
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>

        <Modal
          isOpen={isShowModalLogout}
          fade={false}
          size={"l"}
          backdrop={true}
          keyboard={false}
        >
          <ModalHeader>
            <div className="pull-left">
              <h4>{i18next.t("confirm")}</h4>
            </div>
          </ModalHeader>
          <ModalBody>
            <h4>{i18next.t("confirmLogout")}</h4>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleModelLogout.bind(this)}>
              {i18next.t("yes")}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.handleModelCancelLogout.bind(this)}
            >
              {i18next.t("cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

DefaultHeader.propTypes = propTypes
DefaultHeader.defaultProps = defaultProps

// export default DefaultHeader;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
export default connect(mapStateToProps)(DefaultHeader)
// export default withNamespaces();
