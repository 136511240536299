const initialState = {
    comments: [],
    flowSid: "",
    fetch: false
    // languages: [],
    // configs: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        // case 'SET_USER_INFO':
        //     return {
        //         ...state,
        //         data: action.data
        //     };
        case 'SET_FLOW':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}