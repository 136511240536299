import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import request from "../../services/request.js";
import api from "../../services/api.js";
import local from "../../services/local.js";
import helper from "../../services/helper";
import Loader from "../../controls/Loader";
import { Trans } from "react-i18next";
import i18next from "i18next";
import queryString from "qs";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      username: "",
      loading: true,
      captchaId: 0,
      captchaText: "",
      captcha: null,
      isConfirmPassword: false,
      password: "",
      confirmPassword: "",
    };
  }
  async componentDidMount() {
    if (Object.keys(this.query).length !== 0) {
      if (this.query.token_reset_password && this.query.username) {
        local.set("session", this.query.token_reset_password);
        try {
          let rs = await api.checkTokenForgotPWD();
          if (rs && rs.errorCode === 0) {
            this.setState({ isConfirmPassword: true, loading: false });
          }
        } catch (e) {
          helper.toast("error", e.errorMsg || i18next.t("internalServerError"));
        }
      } else {
        helper.toast("error", "This link invalid!");
        this.props.history.push("/login");
      }
    } else {
      this.loadCaptcha();
    }
  }
  async loadCaptcha() {
    let captInfo = await api.getCaptcha();
    this.setState({
      loading: false,
      captchaId: captInfo.id,
      captcha: captInfo.data,
    });
  }
  async submitForm(e) {
    e.preventDefault();
    if (!this.state.isConfirmPassword) {
      try {
        let rs = await api.reqForgotPassword({
          username: this.state.username,
          captchaId: this.state.captchaId,
          captchaText: this.state.captchaText,
          client: "user",
        });
        if (rs && rs.errorCode == 0) {
          helper.toast("success", rs.errorMsg);
          this.props.history.push("/login");
        } else {
          helper.toast(
            "error",
            rs.errorMsg || i18next.t("internalServerError")
          );
          this.loadCaptcha();
        }
      } catch (err) {
        this.loadCaptcha();
        helper.toast("error", err.errorMsg || i18next.t("internalServerError"));
      }
    } else if (this.state.isConfirmPassword) {
      try {
        let { password, confirmPassword } = this.state;

        if (!password || !confirmPassword) {
          helper.toast("error", "Please enter password or confirm password!");
          return false;
        }

        if (password !== confirmPassword) {
          helper.toast("error", "Password not same!");
          return false;
        }

        let rs = await api.verifyPassword({ password, confirmPassword });
        if (rs && rs.errorCode == 0) {
          helper.toast("success", rs.errorMsg);
          localStorage.clear();
          this.props.history.push("/login");
        } else {
          helper.toast(
            "error",
            rs.errorMsg || i18next.t("internalServerError")
          );
        }
      } catch (err) {
        helper.toast("error", err.errorMsg || i18next.t("internalServerError"));
      }
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col md={12} style={{ textAlign: "center" }}>
                        <img
                          src="assets/img/logo.png"
                          className="login-logo"
                          alt="avatar"
                        />
                        <br />
                        <span
                          style={{ fontSize: "20px", fontFamily: "initial" }}
                        >
                          Corporate{" "}
                        </span>
                        <br />
                        <br />

                        {!this.state.isConfirmPassword && (
                          <span style={{ fontSize: "15px" }}>
                            Forgot Password
                          </span>
                        )}
                        {this.state.isConfirmPassword && (
                          <span style={{ fontSize: "15px" }}>
                            Confirm Password
                          </span>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <form
                      onSubmit={this.submitForm.bind(this)}
                      autoComplete="off"
                    >
                      {!this.state.isConfirmPassword && (
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder={i18next.t("username")}
                            value={this.state.username}
                            onChange={(evt) =>
                              this.setState({ username: evt.target.value })
                            }
                          />
                        </InputGroup>
                      )}
                      {!this.state.isConfirmPassword && (
                        <Row className="mb-4">
                          <Col md={6}>
                            <div
                              className="captcha"
                              dangerouslySetInnerHTML={{
                                __html: this.state.captcha,
                              }}
                            ></div>
                          </Col>
                          <Col md={6}>
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <Input
                                  type="text"
                                  placeholder={i18next.t(
                                    "Please Enter Captcha"
                                  )}
                                  value={this.state.captchaText}
                                  onChange={(evt) =>
                                    this.setState({
                                      captchaText: evt.target.value,
                                    })
                                  }
                                />
                                <InputGroupAddon addonType="prepend">
                                  <Button
                                    color="light"
                                    type="button"
                                    onClick={() => {
                                      this.loadCaptcha();
                                    }}
                                  >
                                    <i className="fa fa-refresh" />
                                  </Button>
                                </InputGroupAddon>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}
                      {this.state.isConfirmPassword && (
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder={i18next.t("password")}
                            value={this.state.password}
                            autoComplete="off"
                            onChange={(evt) =>
                              this.setState({ password: evt.target.value })
                            }
                          />
                        </InputGroup>
                      )}
                      {this.state.isConfirmPassword && (
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder={i18next.t("password")}
                            value={this.state.confirmPassword}
                            autoComplete="off"
                            onChange={(evt) =>
                              this.setState({
                                confirmPassword: evt.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      )}
                      <Row>
                        {!this.state.isConfirmPassword && (
                          <Col xs="12">
                            <Button
                              color="primary"
                              type="submit"
                              className="color-logo-corporate"
                              block
                            >
                              {i18next.t("request")}
                            </Button>
                          </Col>
                        )}
                        {this.state.isConfirmPassword && (
                          <Col xs="12">
                            <Button
                              color="primary"
                              type="submit"
                              className="color-logo-corporate"
                              block
                            >
                              {i18next.t("confirm")}
                            </Button>
                          </Col>
                        )}
                        <Col xs="12" className="text-right">
                          <a
                            href="#"
                            onClick={() => {
                              this.props.history.push("/login");
                            }}
                          >
                            Back to login
                          </a>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
