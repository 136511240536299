import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import queryString from "qs";
import Form from "./SignUpForm";
import i18next from "i18next";

const CompanySignUp = (props) => {
  const [company, setCompany] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    let query = queryString.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (query.user) {
      let com = JSON.parse(query.user);
      setCompany(com);
    } else if (query.message) {
      setMessage(query.message);
    }
  }, []);
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="7">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Row className="mb-4">
                    <Col md={12} style={{ textAlign: "center" }}>
                      <img
                        src="assets/img/logo.png"
                        className="login-logo"
                        alt="avatar"
                      />
                      <br />
                      <span style={{ fontSize: "20px", fontFamily: "initial" }}>
                        Corporate
                      </span>
                    </Col>
                  </Row>
                  {company && (
                    <Form history={props.history} pCompany={company} />
                  )}
                  {message && (
                    <div>
                      <div className="text-align-center">
                        <label className="mr-2">
                          <i className="fa fa-meh-o danger" /> Oops!
                        </label>
                        {i18next.t(message)}
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Button onClick={() => props.history.push("/login")}>
                          {i18next.t("backToLogin")}
                        </Button>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompanySignUp;
