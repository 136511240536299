const myColor = {
    linearG: {
        success: "#4DB749",
        asyst: "#2056A9"
    },
    fColor: {
        success: "#4DB749",
        asyst: "#2056A9"
    }
}

export default myColor