/**
 * @author TuPT
 * @date Feb 19, 2020
 */
import React from 'react';
import helper from '../services/helper';
import MultiSelect from "@khanacademy/react-multi-select";
import i18next from 'i18next'

export default class SelectMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            data: props.ARRAY || [],
            pageId: props.schema.pageId,
            schema: props.schema,
            isCallData: props.isCallData || true,
            fieldsSave: []
        };
    }
    pageInfo = null;
    schema = null;
    componentDidMount() {
        if (this.state.data.length === 0) this.init(this.props.schema.pageId, this.props)
        else {
            this.state.data.map(item => {
                this.renameKey(item, 'key', 'label')
            })
        }
    }
    componentWillReceiveProps(next) {
        if (this.state.isCallData) {
            if (this.props.value !== next.value) {
                this.setState({ value: next.value });
                this.fetchData(this.pageInfo, this.schema, next.value);
            }
        } else {
            this.setState({ value: next.value })
        }
    }
    async init(pageId, props) {
        this.pageInfo = await helper.getPage(pageId);
        this.schema = props.schema;
        this.fetchData(this.pageInfo, this.schema, props.value);
    }
    async fetchData(pageInfo, schema, value) {
        try {
            if (!pageInfo || !schema) {
                return;
            }
            if (schema.fieldsSave && schema.modelSelectField) {
                let fieldsSave = schema.fieldsSave.split(',')
                this.setState({ fieldsSave })

                let rs = await helper.callPageApi(pageInfo, schema.api, {
                    select: fieldsSave.length > 1 ? schema.fieldsSave : `${schema.fieldsSave},${schema.modelSelectField}`
                });

                if (rs && rs.data) {
                    if (fieldsSave.length > 1) {
                        rs.data.map(item => {
                            this.renameKey(item, fieldsSave[0], 'value')
                            this.renameKey(item, fieldsSave[1], 'label')
                        })
                    } else {
                        rs.data.map(item => {
                            this.renameKey(item, schema.fieldsSave, 'value')
                            this.renameKey(item, schema.modelSelectField, 'label')
                        })
                    }


                    if (value && value.length > 0) {
                        value = rs.data.filter(f => value.includes(f.value)).map(i => i.value)
                    } else value = []

                    await this.setState({ data: rs.data, value });
                } else await this.setState({ data: [], value: [] });
            } else {
                helper.toast('error', 'Missing model select field or fields save!')
            }
        } catch (e) {
            helper.toast('error', i18next.t('internalServerError'))
        }
    }
    async saveData(val) {
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }
    renameKey(obj, old_key, new_key) {
        if (obj[old_key])
            if (old_key !== new_key) {
                Object.defineProperty(obj, new_key, // modify old key 
                    // fetch description from object 
                    Object.getOwnPropertyDescriptor(obj, old_key));
                delete obj[old_key];                // delete old key 
            }
    }
    render() {
        let { data, value, fieldsSave } = this.state;

        return (
            <MultiSelect
                options={data}
                selected={value || []}
                disabled={this.props.schema.disabled || false}
                onSelectedChanged={value => {
                    if (fieldsSave.length > 1) {
                        let number, arrValue;
                        if (fieldsSave.indexOf('title') === 1) number = fieldsSave.findIndex(i => i === 'title')
                        value = data.filter(i => value.includes(i.value))
                        value.map(item => {
                            arrValue.push({
                                [fieldsSave[0]]: item.value,
                                [number ? [fieldsSave[number]] : [fieldsSave[1]]]: item.label
                            })
                        })
                        if (this.props.onChange) {
                            this.props.onChange(arrValue)
                        }
                    } else {
                        if (this.props.onChange) {
                            this.props.onChange(value)
                        }
                    }
                }}
            />
        )
    }
}