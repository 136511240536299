import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'
import './scss/custom.css'
import "react-table/react-table.css";
import 'react-table-hoc-fixed-columns/lib/styles.css';
import 'react-image-lightbox/style.css';
// import css for react checkboc-tree
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


import "react-datepicker/dist/react-datepicker.css";
import './index.css';
// Containers
import { DefaultLayout } from './containers';
// Pages
import Login from './views/Login/Login';
import NewLogin from './views/NewLogin';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import NewForgotPassword from './views/NewForgotPassword';
import CompanySignUp from './views/Company/CompanySignUp/CompanySignUp';
import PublicForm from './views/PublicForm/PublicForm';
import NotFoundPage from './views/NotFoundPage/NotFoundPage'

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/404" name="Not Found Page" component={NotFoundPage} />
          <Route exact path="/publicForm/:sid" name="Public Form" component={PublicForm} />
          <Route exact path="/login" name="Login Page" component={NewLogin} />
          <Route exact path="/forgotPassword" name="Forgot Password Page" component={NewForgotPassword} />
          <Route exact path="/join" name="Sign up page" component={CompanySignUp} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
