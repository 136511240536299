import React, { Component } from 'react';
import { Input } from 'reactstrap';
import request from '../services/request';
import { connect } from 'react-redux';
import helper from '../services/helper';
import Label from 'reactstrap/lib/Label';
class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: props.width,
            height: props.height
        }
    }
    async uploadFile(file) {
        try {
            let formData = new FormData();
            formData.append('file', file);
            let rs = await request.upload('/api/upload/uploadFile', formData, 'POST')
            if (rs && rs.errorCode === 0) {
                if (this.props.onChange) {
                    this.props.onChange(rs.data.data);
                }
            }
        } catch (err) {
            helper.toast('error', err.message);
        }
    }
    changeTitle(str) {
        const words = str.split('/');
        return words[words.length - 1];
    }
    render() {
        return (
            <>
                <div className="d-flex">
                    <Input type="file"
                        disabled={this.props.disabled}
                        onChange={evt => {
                            this.uploadFile(evt.target.files[0]);
                        }} />
                    {this.props.value &&
                        <i class="fa fa-times icon-rm-options"
                            aria-hidden="true"
                            onClick={() => {
                                if (this.props.onChange) {
                                    this.props.onChange("");
                                }
                            }} />
                    }
                </div>
                {this.props.value &&
                    <Label className="mt-2 pointer"
                        onClick={() => {
                            window.open(this.props.config.AMAZONE_S3.subConfigs.LINK_STATIC.value +
                                this.props.value, '_blank');
                        }}>
                        {this.changeTitle(this.props.value)}
                    </Label>
                }

            </>)
    }
}
const bindState = state => ({
    config: state.userInfo.configs
});

export default connect(bindState)(Upload);