import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';
import { subscribeUser } from './services/subscription';
let store = configureStore();
ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));

serviceWorker.register();

subscribeUser()