import Text from "./Text";
import SingleModel from "./SingleModel";
import Checkbox from "./Checkbox";
import Date from "./Date";
import Time from "./Time";
import Location from "./Location";
import Image from "./Image";
import TextArea from "./TextArea";
import RichText from "./RichText";
import Enum from "./Enum";
import ArrayImage from "./ArrayImage";
import ArrayModel from "./ArrayModel";
import Password from "./Password";
import Upload from "./Upload";
import Captcha from "./Captcha";
import TabsLanguage from "./TabsLanguage";
import SelectModel from "./SelectModel";
import TableGenerate from "./TableGenerate";
import SelectMultiple from "./SelectMultiple";
import CkEditorSchema from "./CkEditorSchema";
import TableCampaign from "./TableCampaign";
import SearchSelect from "./SearchSelect";
import DateRange from "./DateRange";
import TextJson from "./TextJson";
import DateTime from "./DateTime";
import ReactSelectFetch from "./ReactSelectFetch";
import UploadWorkFLow from "./UploadWorkFLow";
import Signature from "./Signature";
import Button from "./Button";
import UserField from "./UserField";
import Currency from "./Currency";
import Lookup from "./Loockup";
import LookupList from "./LoockupList";
import Label from "./Label";
import TableFile from "./TableFile";
import TableTyping from "./TableTyping";
import TableViewer from "./TableViewer";
import ProcessInfo from "./ProcessInfo";
export default {
  Time,
  DateTime,
  Captcha,
  Upload,
  Password,
  ArrayModel,
  ArrayImage,
  Enum,
  RichText,
  TextArea,
  Image,
  Location,
  Text,
  SingleModel,
  Checkbox,
  Date,
  TabsLanguage,
  SelectModel,
  TableCampaign,
  TableGenerate,
  SelectMultiple,
  CkEditorSchema,
  SearchSelect,
  DateRange,
  TextJson,
  Signature,
  ReactSelectFetch,
  UploadWorkFLow,
  Button,
  UserField,
  Currency,
  Lookup,
  LookupList,
  Label,
  TableFile,
  TableTyping,
  TableViewer,
  ProcessInfo,
};
