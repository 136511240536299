import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Button, CardFooter, Container } from "reactstrap";
import _ from "lodash";
import i18next from "i18next";
import GridLayoutView from "../Company/WorkFlow/GirdLayoutView/GridLayoutView";
import request from "../../services/request";
import Loader from "../../controls/Loader";
import helper from "../../services/helper";
import api from "../../services/api";
function PublicForm(props) {
  const [process, setProcess] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let processRes = await request.request(
        "/form/getForm",
        { sid: props.match.params.sid },
        {},
        "POST",
        true
      );
      if (processRes && processRes.errorCode === 0) {
        let process = processRes.process;
        process.sections.forEach((section) => {
          section.schema = section.schema.filter((schema) => !schema.isTableColumn);
        });
        setProcess(processRes.process);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (section, i) => {
    let newProcess = _.cloneDeep(process);
    newProcess.sections[i] = section;
    setProcess(newProcess);
  };
  const handleClickSubmit = async () => {
    try {
      let metadata = await mappingData(process.sections);
      if (metadata.errorCode === 1) return helper.toast("error", metadata.errorMessage);
      let flowRes = await api.submitPublicForm({
        processId: process.processId,
        name: process.name,
        desc: process.description,
        companyId: process.companyId,
        metadata,
      });
      if (!(flowRes && flowRes.errorCode === 0))
        helper.toast("error", i18next.t("internalServerError"));
      await uploadDataTable(process.sections, flowRes.metadata.sid);
      helper.toast("success", i18next.t("successfully"));
    } catch (error) {
      console.log(error);
    }
  };
  if (isLoading) return <Loader />;
  return (
    <Container className="py-3">
      <Card>
        <CardHeader>
          <h3>{process.name || ""}</h3>
        </CardHeader>
        <CardBody>
          {process.sections &&
            process.sections.map((section, index) => (
              <div className="nit-border" key={index}>
                <div className="d-flex border-none ">
                  <h4>{section.name} </h4>
                </div>
                <div key={index} className="p-2 pt-1 border-none">
                  <GridLayoutView
                    section={section || {}}
                    onChange={(sec) => handleChange(sec, index)}
                  />
                </div>
              </div>
            ))}
        </CardBody>
        <CardFooter>
          <Button color="success" className="pull-right" onClick={handleClickSubmit}>
            Submit
          </Button>
        </CardFooter>
      </Card>
    </Container>
  );
}
export default PublicForm;
const uploadDataTable = async (sections, flowId) => {
  for (let section of sections) {
    for (let schema of section.schema) {
      if (schema.isTable && (schema.value || {}).dataTable) {
        let uploadRes = await api.uploadDataTable({
          flowId,
          sectionId: section.sid,
          schemaField: schema.field,
          dataTable: JSON.stringify(schema.value.dataTable),
        });
        if (!uploadRes || uploadRes.errorCode !== 0)
          return helper.toast("error", `error upload data table ${schema.field}`);
      }
    }
  }
};
const mappingData = async (sections) => {
  sections = await checkAndUpFileTable(sections);
  if (sections.errorCode === 1) return sections;

  let fields = {};
  for (let section of sections) {
    for (let schema of section.schema) {
      if (schema.isTable) {
        Object.assign(fields, { [schema.field]: (schema.value || {}).link });
        for (let column of schema.columns) {
          if (column.toSum === true && (schema.value || {}).sumCol) {
            Object.assign(fields, { [column.field]: schema.value.sumCol[column.field] });
          }
        }
      } else if (schema.type !== "layout") {
        Object.assign(fields, { [schema.field]: schema.value });
      }
    }
  }
  let metadata = { fields: fields };
  return JSON.stringify(metadata);
};
const checkAndUpFileTable = async (sectionsReceived) => {
  try {
    let sections = _.cloneDeep(sectionsReceived);
    for (let section of sections) {
      for (let schema of section.schema) {
        if (schema.isTable) {
          if ((schema.value || {}).error === "error")
            return { errorCode: 1, errorMessage: "Error Data Table" };
        }
      }
    }
    for (let section of sections) {
      for (let schema of section.schema) {
        if (schema.isTable) {
          if ((schema.value || {}).file && !(schema.value || {}).link) {
            let formData = new FormData();
            formData.append("file", schema.value.file);
            let uploadRes = await request.upload("/api/upload/uploadFile", formData, "POST");
            if (!uploadRes || uploadRes.errorCode !== 0)
              return { errorCode: 1, errorMessage: "Error Upload Data Table" };
            schema.value.link = uploadRes.data.data;
          }
        }
      }
    }
    return sections;
  } catch (error) {
    console.log(error);
  }
};
