import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
function ProcessInfo(props) {
  const value = props.value || {};
  return (
    <div className="">
      <Row className="mt-2">
        <Col xs={6} md="3" className="bold">{"Submitted by: "}</Col>
        <Col xs={6}>{value.name}</Col>
      </Row>
      <Row className="mt-2">
        <Col xs={6} md="3" className="bold">{"Employee Group: "}</Col>
        <Col xs={6}>{value.userGroup}</Col>
      </Row>
      <Row className="mt-2">
        <Col xs={6} md="3" className="bold">{"Submission Date: "}</Col>
        <Col xs={6}>{value.createdAt && moment(value.createdAt).format("DD/MM/YYYY HH:mm")}</Col>
      </Row>
    </div>
  );
}

export default ProcessInfo;
