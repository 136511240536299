import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react'
import routes from '../../routes'
import DefaultAside from './DefaultAside'
import DefaultHeader from './DefaultHeader'
import local from '../../services/local'
import api from '../../services/api'
import _ from 'lodash'
import i18next from 'i18next'
// import Pusher from 'pusher-js';
import helper from '../../services/helper'
import SimpleNavBar from './components/SimpleNavBar'

class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      nav: [],
      skip: 0,
      isOpenNavBar: true,
    }
    this.getNotification = this.getNotification.bind(this)
    this.onClickBell = this.onClickBell.bind(this)
    this.onClickSeeMore = this.onClickSeeMore.bind(this)
  }

  async componentDidMount() {
    try {
      var session = await local.get('session')
      if (!session) {
        this.props.history.replace('/login')
      } else {
        let meta = await api.getMeta()
        let rsLanguage = await api.getLanguage()
        let config = await api.getConfig({
          keys: [
            'IMAGE',
            'PAGE_ID',
            'FILE_SETTING',
            'REGEX',
            'AMAZONE_S3',
            'ROLE_ACCESS_MENU',
          ],
        })
        meta.pages.map((page) => {
          for (var i in page.schema) {
            page.schema[i].pageId = page.sid
          }
          return null
        })
        local.set('meta', JSON.stringify(meta))
        let userInfo = local.get('user')
        // this.receiveNotify(userInfo, config && config.data || {});
        let ntf = await this.getNotification(0, 10)
        let nav = this.calculateNav(meta.menus, userInfo.role)
        this.setState({
          loading: false,
          nav,
          notifications: ntf.notifications,
          newNum: ntf.newNum,
        })
        this.props.dispatch({
          type: 'SET_DATA',
          payload: {
            languages: (rsLanguage && rsLanguage.data) || [],
            configs: (config && config.data) || {},
            data: userInfo,
            pages: meta.pages,
            menus: meta.menus,
          },
        })
      }
      if (window.location.hostname !== 'localhost')
        window.addEventListener('beforeunload', this.handleLeavePage)
    } catch (err) {
      console.log('loi roi , err here', err)
      this.props.history.replace('/login')
    }
  }

  /**
   * @author TuPT
   * @createdAt 06/06/2020
   * @description confirm when reload or leave page
   */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage)
  }

  handleLeavePage(e) {
    const confirmationMessage = 'Some message'
    e.returnValue = confirmationMessage
    return confirmationMessage
  }
  calculateNav(allMenus, role) {
    let menus = allMenus

    let items = []
    menus.map((m) => {
      if (!m.parent) {
        m.isOpen = false
        if (!m.url) m.url = 'url'
        items.push(m)
      }
      return null
    })
    menus.map((m) => {
      if (!m.parent) return null
      for (var i = 0;i < items.length;i++) {
        if (items[i].sid === m.parent) {
          if (!items[i].children) items[i].children = []
          items[i].children.push(m)
        }
      }
      return null
    })
    return { items }
  }
  async onClickBell() {
    try {
      let { notifications } = this.state
      let check = notifications.filter((n) => n.isNew == true)
      if (check.length >= 1) {
        await api.updateIsNewNoti({ channel: 'push' })
      }
    } catch (error) {
      console.log('err', error.stack)
    }
  }
  async onClickSeeMore(skip, limit) {
    try {
      return await this.getNotification(skip, limit)
    } catch (error) {
      console.log('err', error.stack)
    }
  }

  async getNotification(skip, limit) {
    let { notifications, newNum } = this.state
    let data = await helper.getNotify(skip, limit)
    notifications = data.notifications
    newNum = data.newNum
    return { notifications, newNum }
  }
  render() {
    if (this.state.loading) return <p>{i18next.t('PROCESSING')}</p>
    return (
      <div className='app'>
        <AppHeader fixed>
          <DefaultHeader
            newNum={this.state.newNum}
            count={this.state.count}
            notifications={this.state.notifications}
            onClickBell={this.onClickBell}
            onClickSeeMore={this.onClickSeeMore}
            history={this.props.history}
            onTogglerNavBar={() => {
              this.setState({ isOpenNavBar: !this.state.isOpenNavBar })
            }}
          />
        </AppHeader>
        <div className='app-body'>
          {/* <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav
              navConfig={this.state.nav}
              location={this.props.location}
            />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar> */}
          <SimpleNavBar
            items={this.state.nav.items}
            vertical={true}
            actTab={1}
            color={'asyst'}
            location={this.props.location}
            isOpenNavBar={this.state.isOpenNavBar}
          />
          <main className='main' style={{ backgroundColor: '#e6e6e6' }}>
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            <Container fluid className='py-4'>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null
                })}
                <Redirect from='/' to='/login' />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        {/* <AppFooter>
          <DefaultFooter />
        </AppFooter> */}
      </div>
    )
  }
}

// export default DefaultLayout;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo }
}
export default connect(mapStateToProps)(DefaultLayout)
