import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../controls/Loader';
// import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import mammoth from "mammoth";
import { Card, Table } from 'reactstrap';
import i18next from 'i18next';


function index({ objData = {}, type, config = {} }) {

    if (!type) return <Loader />;

    if (type === 'word') {
        mammoth.convertToHtml({ arrayBuffer: objData.data }, {
            styleMap: [
                "p[style-name='Section Title'] => h1:fresh",
                "p[style-name='Subsection Title'] => h2:fresh"
            ],
            includeDefaultStyleMap: false
        })
            .then((result) => {
                const docEl = document.createElement('div');
                docEl.className = 'document-container';
                docEl.innerHTML = result.value;
                document.getElementById('docx').innerHTML = docEl.outerHTML;
            })
            .catch((e) => {
                console.log('alexei: something went wrong', e);
            })
    }

    return (<>
        {type === 'pdf' && <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
            overflow: "auto"
        }} >

            {
                // <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js" />
                // <Viewer
                //     fileUrl={objData.data}
                //     defaultScale={SpecialZoomLevel.PageWidth}
                // />
            }

        </div>}
        {type === 'excel' && <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
            overflow: "auto"
        }}>
            <Card body outline className="restrict-card table-responsive">
                <Table className="ExcelTable2007">
                    <thead>
                        <tr>
                            {objData[0].map((i, idx) => <th key={'th_' + idx}>{i18next.t(i || '')}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {objData.slice(1).map((item, index) => <tr key={'tr_' + index}>{
                            item.map((i, idx) => <td key={'td_' + idx}>{i || ''}</td>)
                        }</tr>)}
                    </tbody>
                </Table>
            </Card>
        </div>}
        {type === 'word' && <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '650px',
            overflow: "auto"
        }} >
            <div id="docx"></div>
        </div>}
        {type === 'image' && <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
            overflow: "auto"
        }}>
            <img style={{ padding: '20px', height: '500px' }} src={config.IMAGE.subConfigs.URL_S3.value + objData} />
        </div>}
    </>);
}

const bindState = state => ({
    config: state.userInfo.configs
})

export default connect(bindState)(index);