import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux'
import i18next from "i18next";
import { Input, Label } from "reactstrap";
import helper from "../services/helper";
import { ExcelRenderer } from "react-excel-renderer";
import _ from "lodash";

const TableFile = (props) => {
  const [schema, setSchema] = useState(props.schema);
  const [columnsTable, setColumnsTable] = useState([]);
  useEffect(() => {
    if ((schema.value || {}).dataTable) {
      let value = {
        error: null,
        dataTable: schema.value.dataTable,
        sumCol: calcSumCol(schema.value.dataTable, schema.columns),
      };
      if (props.onChange) props.onChange(value);
    }
  }, []);
  useEffect(() => {
    setColumnsTable(calcColumnsTable(calcSumCol((schema.value || {}).dataTable, schema.columns)));
  }, [schema]);
  useEffect(() => {
    setSchema(props.schema);
  }, [props.schema]);
  const calcSumCol = (dataTable, columns) => {
    let sumCol = {};
    if (dataTable) {
      for (let col of columns) {
        if (col.toSum) {
          for (let row of dataTable) {
            if (sumCol[col.field] === undefined) sumCol[col.field] = row[col.field];
            else sumCol[col.field] += row[col.field];
          }
        }
      }
    }
    return sumCol;
  };
  const headerCellStyle = (label, value) => {
    return (
      <div>
        <div style={{ padding: "5px" }}>{label}</div>
        <div style={{ padding: "5px", borderTop: "1px solid #ddd" }}>{value}</div>
      </div>
    );
  };
  const calcErrorStatus = (value) => {
    return value === undefined ? "" : !isNaN(Number(value)) ? value : "error data";
  };
  const calcColumnsTable = (sumCol) => {
    let col = [
      {
        Header: headerCellStyle("no", "Sum"),
        width: 50,
        fixed: "left",
        accessor: "no",
        headerStyle: { padding: "0px", boxShadow: "none" },
      },
    ];
    schema.columns.map((s) => {
      col.push({
        Header: headerCellStyle(s.field, calcErrorStatus(sumCol[s.field])),
        accessor: s.field,
        headerStyle: { padding: "0px", boxShadow: "none" },
      });
    });
    return col;
  };
  const uploadFile = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file.name.split(".").pop() !== "xlsx")
      return helper.toast("error", "Unsupported this file.");
    if (!file) return;

    let value = {};
    value.file = file;
    ExcelRenderer(file, async (err, res) => {
      if (err) return helper.toast("error", err.message);
      let data = [],
        error = null;
      let LIMIT_ROW = props.configs.FILE_SETTING.subConfigs?.LIMIT_ROW.value
      if (LIMIT_ROW && res.rows.length > LIMIT_ROW + 1)
        return helper.toast('error', i18next.t(`Batch limit row is ${LIMIT_ROW}.Please check again `))
      res.rows.splice(1).forEach((item, index) => {
        let row = { no: index + 1 };
        schema.columns.map((s, i) => {
          row[s.field] = item[i + 1];
          if (s.toSum) {
            if (!Number.isFinite(item[i + 1])) error = "error";
          }
        });
        data.push(row);
      });
      value.error = error;
      value.dataTable = data;
      value.sumCol = calcSumCol(data, schema.columns);
      let newSchema = _.cloneDeep(schema);
      newSchema.value = value;
      setSchema(newSchema);
      if (props.onChange) props.onChange(value);
    });
  };
  return (
    <div style={{ height: "calc(100% - 25px)", overflowY: "auto" }}>
      {!schema.disabled && (
        <div>
          <input
            type="file"
            onChange={(e) => uploadFile(e)}
            onClick={(e) => {
              e.target.value = "";
            }}
            id={"upload" + schema.field}
            style={{ display: "none" }}
          />
          <div style={{ display: "flex" }}>
            <div>
              <Label for={"upload" + schema.field} id={"lbl_upload" + schema.field} className="btn btn-success pointer">
                <i className="fa fa-upload mr-1" />
                {i18next.t("Upload")}
              </Label>
            </div>
            <div className="m-2">{(schema.value || {}).file ? schema.value.file.name : ""}</div>
          </div>
        </div>
      )}
      <ReactTable
        data={(schema.value || {}).dataTable ? schema.value.dataTable : []}
        defaultPageSize={5}
        columns={columnsTable}
        className="-highlight"
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  configs: state.userInfo.configs,
})

export default connect(mapStateToProps)(TableFile)