import React, { Component } from "react";
import { Modal, ButtonGroup, Button } from "reactstrap";

import _ from "lodash";

const Constants = {
    EDITABLE: 'editable',
    READONLY: 'readonly',
    HIDDEN: 'hidden'
}

class PermissionField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPermissionFiled: props.showPermissionFiled,
            currentSchema: props.currentSchema,
            schema: props.schema,
            currentKey: props.currentKey,
            key: props.keyF
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            showPermissionFiled: props.showPermissionFiled,
            currentSchema: props.currentSchema,
            schema: props.schema,
            currentKey: props.currentKey,
            key: props.keyF
        })
    }
    calColor = (access) => {
        let { schema } = this.props, status = ''
        if (!this.state.access) {
            if (schema.disabled && !schema.hidden) {
                status = "readonly"
            } else if (schema.hidden) {
                status = "hidden"
            } else status = 'editable'
        }

        if (access === this.state.access || access === status) {
            return 'success';
        }
        return '';
    }
    onClickButton = (access) => {
        let { currentSchema } = this.state, showPermissionFiled = false;
        this.setState({ access: access });
        this.props.handlePermissionField(currentSchema.field, access, showPermissionFiled)

    }
    render() {
        let { showPermissionFiled, currentKey, key } = this.state;
        return (
            <>
                {showPermissionFiled && (currentKey === key) &&
                    <ButtonGroup style={style}>
                        <Button
                            style={{ fontSize: '10px' }}
                            onClick={() => this.onClickButton(Constants.EDITABLE)}
                            color={this.calColor(Constants.EDITABLE)}>
                            Editable
                        </Button>
                        <Button
                            style={{ fontSize: '10px' }}
                            onClick={() => this.onClickButton(Constants.READONLY)}
                            color={this.calColor(Constants.READONLY)}>
                            Readonly
                        </Button>
                        <Button
                            style={{ fontSize: '10px', marginRight: '-1px' }}
                            onClick={() => this.onClickButton(Constants.HIDDEN)}
                            color={this.calColor(Constants.HIDDEN)}>
                            Hidden
                    </Button>
                    </ButtonGroup>}
            </>
        );
    }
}

export default PermissionField;
let style = {
    border: '2px solid #4dbd74',
    borderRadius: '6px',
    background: 'white',
    boxShadow: '7px 6px 6px rgb(77 189 116)'
}
