import React from "react";
const NotFoundPage = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <h1>Oops!</h1>
      <h2>404 Not Found</h2>
      <div>Sorry, an error has occured, Requested page not found!</div>
    </div>
  );
};
export default NotFoundPage;
