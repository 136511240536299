import React from "react";
import Loadable from "react-loadable";

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import("./views/User/Profile"),
  loading: Loading,
});

const PageEditor = Loadable({
  loader: () => import("./views/PageManager/PageEditor"),
  loading: Loading,
});
const UserPageEditor = Loadable({
  loader: () => import("./views/UserPageManager/UserPageEditor"),
  loading: Loading,
});
const FormViewer = Loadable({
  loader: () => import("./views/Form/FormViewer"),
  loading: Loading,
});
const ListViewer = Loadable({
  loader: () => import("./views/List/ListViewer"),
  loading: Loading,
});
const Role = Loadable({
  loader: () => import("./views/Role/Role"),
  loading: Loading,
});
const APIDefinition = Loadable({
  loader: () => import("./views/APIDefinition/APIDefinition"),
  loading: Loading,
});
const ServiceManagement = Loadable({
  loader: () => import("./views/ServiceManagement"),
  loading: Loading,
});
const UserGroup = Loadable({
  loader: () => import("./views/UserManagement/UserGroup"),
  loading: Loading,
});
const LinkBankAccount = Loadable({
  loader: () => import("./views/LinkBankAccount/LinkBankAccount"),
  loading: Loading,
});
const NewTransaction = Loadable({
  loader: () => import("./views/NewTransaction/NewTransaction"),
  loading: Loading,
});
const CompanySetting = Loadable({
  loader: () => import("./views/Admin/CompanyPage/Setting"),
  loading: Loading,
});
const AuthorizeMatrix = Loadable({
  loader: () => import("./views/Company/AuthorizeMatrix"),
  loading: Loading,
});
const ListCashCode = Loadable({
  loader: () => import("./views/Company/CashCode/listCashCode"),
  loading: Loading,
});
const WorkFlow = Loadable({
  loader: () => import("./views/Company/WorkFlow/WorkFlow"),
  loading: Loading,
});
const Process = Loadable({
  loader: () => import("./views/Company/Process/ProcessView"),
  loading: Loading,
});
const Flow = Loadable({
  loader: () => import("./views/Company/Flow/index"),
  loading: Loading,
});
const NewItem = Loadable({
  loader: () => import("./views/Company/Flow/NewItem"),
  loading: Loading,
});
const ProcessList = Loadable({
  loader: () => import("./views/Process/ProcessList"),
  loading: Loading,
});
const MyDashboard = Loadable({
  loader: () => import("./views/Company/MyDashboard/MyDashboard"),
  loading: Loading,
});
const Categories = Loadable({
  loader: () => import("./views/Categories/Categories"),
  loading: Loading,
});
const WorkFlowTemplate = Loadable({
  loader: () => import("./views/WorkFlowTemplate/WorkFlowTemplate"),
  loading: Loading,
});
const AdvanceSalary = Loadable({
  loader: () => import("./views/AdvanceSalary"),
  loading: Loading,
});
const ImportCorEmployeeLayout = Loadable({
  loader: () => import("./views/AdvanceSalary/ImportLayout/ImportLayout"),
  loading: Loading,
});
const DetailImport = Loadable({
  loader: () => import("./views/AdvanceSalary/ImportLayout/DetailImport"),
  loading: Loading,
});
const AdvanceSetting = Loadable({
  loader: () => import("./views/AdvanceSetting"),
  loading: Loading,
});
const Repayment = Loadable({
  loader: () => import("./views/AdvanceSalary/Repayment"),
  loading: Loading,
});
const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/pageEditor",
    name: "Page Management",
    component: PageEditor,
  },
  {
    path: "/userPageEditor",
    name: "Page Management",
    component: UserPageEditor,
  },
  {
    path: "/form",
    name: "FormViewer",
    component: FormViewer,
  },
  {
    path: "/list",
    name: "ListViewer",
    component: ListViewer,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/role",
    name: "Role",
    component: Role,
  },
  {
    path: "/APIDefinition",
    name: "APIDefinition",
    component: APIDefinition,
  },
  {
    path: "/UserGroup",
    name: "UserGroup",
    component: UserGroup,
  },
  {
    path: "/LinkBankAccount",
    name: "LinkBankAccount",
    component: LinkBankAccount,
  },
  {
    path: "/NewTransaction",
    name: "NewTransaction",
    component: NewTransaction,
  },
  {
    path: "/company/setting",
    name: "Company Setting",
    component: CompanySetting,
  },
  {
    path: "/authorizeMatrix",
    name: "Authorize Matrix",
    component: AuthorizeMatrix,
  },
  {
    path: "/listCashCode",
    name: "List Cash Code",
    component: ListCashCode,
  },
  {
    path: "/workFlow",
    name: "Work Flow",
    component: WorkFlow,
  },
  {
    path: "/process",
    name: "Process",
    component: Process,
  },
  {
    path: "/flow",
    name: "Flow",
    component: Flow,
  },
  {
    path: "/newItem",
    name: "NewItem",
    component: NewItem,
  },
  {
    path: "/listProcess",
    name: "List Process",
    component: ProcessList,
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
  },
  {
    path: "/myDashboard",
    name: "MyDashboard",
    component: MyDashboard,
  },
  {
    path: "/workFlowTemplate",
    name: "Work Flow Template",
    component: WorkFlowTemplate,
  },
  {
    path: "/employeeInfo",
    name: "AdvanceSalary",
    component: AdvanceSalary,
  },
  {
    path: "/importCorEmployee",
    name: "ImportCorEmployeeLayout",
    component: ImportCorEmployeeLayout,
  },
  {
    path: "/importDetail",
    name: "DetailImport",
    component: DetailImport,
  },
  {
    path: "/advanceSetting",
    name: "AdvanceSetting",
    component: AdvanceSetting,
  },
  {
    path: "/repayment",
    name: "Repayment",
    component: Repayment,
  },
];

export default routes;
