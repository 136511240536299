import React, { Component } from "react";
import { Row, Col, Button, Input, Label } from "reactstrap";
import ReactTable from "react-table";
import _ from "lodash";

export default class TableTyping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: props.schema,
      dataTable: (props.schema.value || {}).dataTable || [],
      columnsTable: props.schema.columnsTable || [],
      inputFocus: [0, ""],
    };
  }
  componentDidMount() {
    let { dataTable, schema } = this.state;
    let sumCol = this.calcSumCol(dataTable, schema.columns);
    let columnsTable = this.calcColumnsTableTyping(sumCol, schema);
    this.setState({ columnsTable });
    if (dataTable.length > 0) {
      let value = {
        error: null,
        dataTable,
        sumCol,
      };
      if (this.props.onChange) this.props.onChange(value);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.schema !== this.props.schema) {
      let tem = this.calcColumnsTableTyping(
        this.calcSumCol([], this.props.schema.columns),
        this.props.schema
      );
      console.log(tem, this.props.schema.columns);
      this.setState({
        columnsTable: tem,
      });
    }
  }
  calcSumCol = (dataTable, columns) => {
    let sumCol = {};
    dataTable.forEach((r) => {
      columns.forEach((column) => {
        if (column.toSum) {
          let val = r[column.field] !== "" ? Number(r[column.field]) : "error";
          if (sumCol[column.field] !== undefined) sumCol[column.field] += val;
          else sumCol[column.field] = val;
        }
      });
    });
    return sumCol;
  };
  handleChangeCellData(row, col, val) {
    let dataTable = _.cloneDeep(this.state.dataTable);
    dataTable[row][col] = val;
    let columns = this.state.schema.columns;
    let sumCol = this.calcSumCol(dataTable, columns);
    let columnsTable = this.calcColumnsTableTyping(sumCol, this.state.schema);
    this.setState({ dataTable, columnsTable });
    let error = null;
    for (let row of dataTable) {
      for (let cell in row) {
        if (!row[cell]) error = "error";
        break;
      }
    }
    if (!error) {
      for (let column of columns) {
        if (column.toSum && isNaN(Number(sumCol[column.field]))) {
          error = "error";
          break;
        }
      }
    }

    let value = {
      dataTable,
      error,
      sumCol,
    };
    if (this.props.onChange) this.props.onChange(value);
  }
  headerCellStyle = (label, value) => {
    return (
      <div>
        <div style={{ padding: "5px" }}>{label}</div>
        <div style={{ padding: "5px", borderTop: "1px solid #ddd" }}>{value}</div>
      </div>
    );
  };
  calcErrorStatus = (value) => {
    return value === undefined ? "" : !isNaN(Number(value)) ? value : "error data";
  };
  calcColumnsTableTyping = (sumCol, schema) => {
    let col = [
      {
        Header: this.headerCellStyle("no", "Sum"),
        width: 50,
        fixed: "left",
        accessor: "no",
        headerStyle: { padding: "0px", boxShadow: "none" },
        Cell: (cellInfo) => {
          return <div>{cellInfo.index + 1}</div>;
        },
      },
    ];
    for (let column of schema.columns) {
      col.push({
        Header: this.headerCellStyle(column.field, this.calcErrorStatus(sumCol[column.field])),
        accessor: column.field,
        headerStyle: { padding: "0px", boxShadow: "none" },
        Cell: (cellInfo) => {
          const cellValue = this.state.dataTable[cellInfo.index][cellInfo.column.id];
          const columns = this.state.schema.columns;
          let defaultValue = columns.find((c) => c.field === cellInfo.column.id).defaultValue;
          if (defaultValue && defaultValue.length > 0) {
            return (
              <select
                value={cellValue || ""}
                onChange={(e) => {
                  this.handleChangeCellData(cellInfo.index, cellInfo.column.id, e.target.value);
                }}
                style={STYLE.cellInput}
              >
                <option value=""></option>
                {defaultValue.map((v, i) => (
                  <option key={i} value={v}>
                    {v}
                  </option>
                ))}
              </select>
            );
          } else {
            return (
              <input
                autoFocus={
                  this.state.inputFocus[0] === cellInfo.index &&
                  this.state.inputFocus[1] === cellInfo.column.id
                    ? true
                    : false
                }
                name="input"
                type="text"
                onChange={(e) => {
                  this.handleChangeCellData(cellInfo.index, cellInfo.column.id, e.target.value);
                  this.setState({ inputFocus: [cellInfo.index, cellInfo.column.id] });
                }}
                value={cellValue || ""}
                style={STYLE.cellInput}
              />
            );
          }
        },
        getProps: (state, rowInfo, column) => ({ style: { padding: "0px" } }),
      });
    }
    return col;
  };
  handleAddRow() {
    let dataTable = _.cloneDeep(this.state.dataTable);
    dataTable.unshift({});
    this.setState({ dataTable });
    let value = {
      dataTable,
      error: null,
      sumCol: this.calcSumCol(dataTable, this.state.schema.columns),
    };
    if (this.props.onChange) this.props.onChange(value);
  }
  render() {
    let { dataTable, schema, columnsTable } = this.state;
    return (
      <div style={{ height: "calc(100% - 25px)", overflowY: "auto" }}>
        {!schema.disabled && (
          <div>
            <Button color="success" onClick={() => this.handleAddRow()}>
              Add row
            </Button>
          </div>
        )}
        <ReactTable
          data={dataTable}
          defaultPageSize={5}
          columns={columnsTable}
          className="-highlight"
        />
      </div>
    );
  }
}

const STYLE = {
  cellInput: {
    width: "100%",
    height: "100%",
    padding: "8px",
    border: "none",
  },
};
